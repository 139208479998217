import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";
import UserSettings from "src/pages/dashboard/settings/UserSettings";
import Badges from "src/pages/dashboard/badges/Badges";
import NotificationsView from "src/pages/dashboard/notifications/NotificationsView";

// ----------------------------------------------------------------------

const HomePage = lazy(() => import("src/pages/dashboard/home"));

// User Profile
const UserProfile = lazy(() => import("src/pages/dashboard/profile/userProfile"));

// Programs
const CurrentProgram = lazy(() => import("src/pages/dashboard/programs/currentProgram"));
const PastPrograms = lazy(() => import("src/pages/dashboard/programs/pastPrograms"));
const AllPrograms = lazy(() => import("src/pages/dashboard/programs/allPrograms"));

// Gifts
const ReceivedGifts = lazy(() => import("src/pages/dashboard/gifts/ReceivedGifts"));
const SentGifts = lazy(() => import("src/pages/dashboard/gifts/SentGifts"));
const CustomizeGift = lazy(() => import("src/pages/dashboard/gifts/CustomizeGift"));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <HomePage />, index: true },
      {
        path: "profile",
        children: [{ element: <UserProfile />, index: true }],
      },
      {
        path: "programs",
        children: [
          { element: <CurrentProgram />, index: true },
          { path: "past", element: <PastPrograms /> },
          { path: "all", element: <AllPrograms /> },
        ],
      },
      {
        path: "gifts",
        children: [
          { element: <ReceivedGifts />, index: true },
          { path: "sent", element: <SentGifts /> },
          { path: "customize", element: <CustomizeGift /> },
        ],
      },
      {
        path: "settings",
        element: <UserSettings />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "notifications",
        element: <NotificationsView />
      }
    ],
  },
];
