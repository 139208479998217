import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios/index";
import { useSnackbar } from "src/components/snackbar";
import { useLocales } from "src/locales";
import { IGetItemParams, IGetListParams, IPatchParams, IPostParams } from "src/types/api";
import { localStorageGetItem } from "src/utils/storage-available";
import { axiosApi } from "./axios";

const useApiServices = () => {
  const { enqueueSnackbar } = useSnackbar();
  const locale = localStorageGetItem("i18nextLng");
  const { t } = useLocales();

  // Get list service
  const useGetListApi = <T,>({ parameters, url, onSuccess, onError, enabled = true }: IGetListParams) => {
    return useQuery<T, AxiosError>(
      [url, parameters],
      async () => await axiosApi.get(url, { params: parameters, headers: { "x-locale": locale } }),
      {
        onSuccess: onSuccess,
        onError: onError,
        enabled,
      }
    );
  };

  // Get item service
  const useGetItemApi = <T,>({ url, id, onSuccess, onError, enabled = true }: IGetItemParams) => {
    return useQuery<T, AxiosError>(
      [url],
      async () =>
        await axiosApi.get(`${url}${id ? `/${id}` : ""}`, { headers: { "x-locale": locale } }),
      {
        onSuccess: onSuccess,
        onError: onError,
        enabled
      }
      );
  };

  // Post service
  const usePostApi = <T,>({
    url,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
  }: IPostParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      (requestData) => axiosApi.post(url, requestData, { headers: { "x-locale": locale } }),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess(data);
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // Put service
  const usePutApi = <T,>({
    url,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
  }: IPostParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      () => axiosApi.put(url),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess(data);
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // Patch service
  const usePatchApi = <T,>({
    url,
    id,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
  }: IPatchParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      (requestData) =>
        axiosApi.patch(`${url}/${id}`, requestData, { headers: { "x-locale": locale } }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess();
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // For show snackbar when api return a error
  const handleApiErrorwithNotistack = (error: AxiosError) => {
    enqueueSnackbar({
      variant: "error",
      message: error?.message,
      anchorOrigin: { vertical: "top", horizontal: locale === "ar" ? "left" : "right" },
    });
  };

  // For show snackbar when api successfully
  const handleApiSuccesswithNotistack = () => {
    enqueueSnackbar({
      variant: "success",
      message: t("completedSuccessfully"),
      anchorOrigin: { vertical: "top", horizontal: locale === "ar" ? "left" : "right" },
    });
  };

  return {
    useGetListApi,
    useGetItemApi,
    usePostApi,
    usePatchApi,
    handleApiErrorwithNotistack,
    handleApiSuccesswithNotistack,
    usePutApi
   };
};

export default useApiServices;
