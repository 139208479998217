// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';
import Button from '@mui/material/Button';
import { useBoolean } from 'src/hooks/use-boolean';
import LogoutPopup from './LogoutPopup';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import DarkModeLogo from 'src/components/dark-mode-logo/dark-mode-logo';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useMockedUser();

  const navData = useNavData();

  const dialog = useBoolean();

  const { t } = useLocales();

  const settings = useSettingsContext();

  const isLight = settings.themeMode === 'light';

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          justifyContent: 'space-between',
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Box>
          {isLight ? (
            <Logo sx={{ mx: 'auto', my: 2 }} />
          ) : (
            <DarkModeLogo sx={{ mx: 'auto', my: 2 }} />
          )}
          <NavSectionMini
            data={navData}
            config={{
              currentRole: user?.role || 'admin',
            }}
          />
        </Box>
        <Button onClick={() => dialog.onTrue()} variant="outlined">
          {t('navbar.logout')}
        </Button>
      </Stack>
      <LogoutPopup dialog={dialog} />
    </Box>
  );
}
