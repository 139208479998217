import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Box, Typography, Button, Stack } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import useApiServices from 'src/api/useApiServices';
import { API_URLS, ResDataType } from 'src/api/apiUrls';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSwitch } from 'src/components/hook-form';
import { NOTIFICATIONS_LIST } from './notifications-schema';
import { useLocales } from 'src/locales';

const NotificationsView = () => {
  const settings = useSettingsContext();
  const { useGetListApi, usePostApi } = useApiServices();
  const { t } = useLocales();
  const {
    data: userData,
    isLoading,
    error,
  } = useGetListApi<ResDataType['userProfile']>({
    url: API_URLS.USER_PROFILE,
  });

  const notifications = useForm({
    defaultValues: [
      {
        name: 'newProgramNotification',
        value: false,
      },
      {
        name: 'finishProgramNotification',
        value: false,
      },
      {
        name: 'receivedGiftNotification',
        value: false,
      },
      {
        name: 'winningBadgeNotification',
        value: false,
      },
      {
        name: 'deliveredGiftNotification',
        value: false,
      },
    ],
  });

  const { handleSubmit, setValue } = notifications;

  const { mutate } = usePostApi({ url: API_URLS.PROFILE });

  const onSubmit = handleSubmit(async (data: any) => {
    mutate(data);
  });

  useEffect(() => {
    NOTIFICATIONS_LIST(userData!).forEach((e) => setValue(e.name as any, e.value as any));
  }, [isLoading]);

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : 'xl'}>
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <FormProvider onSubmit={onSubmit} methods={notifications}>
            <Stack spacing={2} width={{ xs: '100%', sm: '50%' }}>
              {NOTIFICATIONS_LIST(userData!).map((setting) => (
                <Box
                  key={setting.name}
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Typography>{t(`settings.notifications.${setting.name}`)}</Typography>
                  <RHFSwitch {...setting} label="" />
                </Box>
              ))}
              <Button
                sx={{ width: { xs: '100%', md: '40%' } }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('settings.notifications.saveChanges')}
              </Button>
            </Stack>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default NotificationsView;
