// @mui
import {Typography, DialogContent, Dialog, DialogTitle, IconButton} from '@mui/material';
// hooks
import { Stack } from '@mui/material';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

export default function ChangePasswordPopup({ dialog }: { dialog: any }) {
  return (
    <Dialog open={dialog.value} maxWidth="sm" onClose={dialog.onFalse}>
      <DialogTitle textAlign="end" p="0px !important">
        <IconButton onClick={dialog.onFalse}>
          <Iconify icon="carbon:close-outline" color="background.contrast" width={30} height={30} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center" spacing={4} px={3} pb={5} textAlign="center">
          {/* <Image width="25%" src={popupImage.src} /> */}
          <Iconify icon="simple-line-icons:check" width="72px" color="primary.main" />
          <Typography fontWeight={600} fontSize="32px" lineHeight="120%">
            Your password has been changed successfully.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
