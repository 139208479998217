import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  sx?: any;
};

export default function FormProvider({ children, onSubmit, methods, sx }: Props) {
  return (
    <Form {...methods}>
      <form style={sx} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
