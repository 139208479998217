import { Controller, useFormContext } from "react-hook-form";
// @mui
import { TextFieldProps } from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  label?: string;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

export default function RHFDatePicker ({
  name,
  label,
  isRequired = true,
  helperText,
  type,
  minDate,
  maxDate,
  ...other
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
        return (
          <DatePicker
            {...field}
            value={field.value || null}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "16px",
                },
              },
            }}
            slotProps={{
              textField: {
                error: !!error,
                fullWidth: true,
                helperText: error ? error?.message : helperText,
              },
            }}
            minDate={minDate!}
            maxDate={maxDate!}
          />
        );
      }}
    />
  );
}
