import { translationJsonType } from "./type";

const arabicJson: translationJsonType = {
  public: {
    dropDownPlaceHolder: "اختر من القائمة",
  },
  programButtons: {
    continueDate: "حدد تاريخ المتابعة",
    restartDate: "حدد تاريخ الإعادة",
  },
  table: {
    rowsPerPageLabel: "صف في الصفحة: ",
  },
  emailOrPhoneInvalid: "البريد الإلكتروني / رقم الهاتف خاطئ",
  editPersonalInfoDialog: {
    header: "تحرير التفضيلات الشخصية",
    firstName: {
      label: "الاسم الأول",
      placeholder: "أدخل الاسم الأول",
    },
    lastName: {
      label: "اسم العائلة",
      placeholder: "إدخال اسم العائلة",
    },
    email: {
      label: "عنوان البريد الإلكتروني",
      placeholder: "أدخل عنوان البريد الالكتروني",
    },
    save: "حفظ",
    cancel: "إلغاء",
    formErrorMessages: {
      onlyLetters: "هذا الحقل يجب أن يحتوي على حروف فقط",
    },
  },
  cannotRestartProgramDialog: {
    header: "للأسف, لا يمكنك إعادة تشغيل هذا البرنامج الآن",
    paragraph:
      "يبدو أنك اشتركت في برنامج آخر في الوقت الحالي. لإعادة تشغيل هذا البرنامج، الرجاء إنهاء البرنامج الحالي أولاً ثم محاولة إعادة تشغيل البرنامج.",
    button: "العودة إلى البرنامج الحالي",
  },
  notificationsPage: {
    markAllAsRead: "تحديد كل الرسائل كمقروءة",
    tabs: {
      all: "كل الرسائل",
      unread: "غير المقروءة",
    },
  },
  navbar: {
    home: "الصفحة الرئيسية",
    programs: {
      title: "البرامج",
      types: {
        current: "البرنامج الحالي",
        past: "البرامج الماضية",
        all: "كل البرامج",
      },
    },
    gifts: {
      title: "الهدايا",
      types: {
        received: "الهدايا المرسلة إلي",
        sent: "الهدايا المرسلة",
      },
    },
    settings: "الإعدادات",
    badges: "الشارات",
    logout: "تسجيل الخروج",
  },
  header: {
    notificationsPopover: {
      title: "الإشعارات",
      seeAll: "إظهار الكل",
    },
    languagesPopover: {
      English: "الإنكليزية",
      Spanish: "الأسبانية",
      Arabic: "العربية",
    },
    settingsDrawer: {
      title: "إعدادات",
      mode: "الوضع",
      contrast: "التباين",
      direction: "الاتجاه",
      layout: "التخطيط",
      stretch: "تمديد",
      presets: "الإعدادات المسبقة",
      fullScreen: "تكبير الشاشة",
      exitFullScreen: "الخروج من الشاشة الكاملة",
    },
    profilePopover: {
      personalInfo: "المعلومات الشخصية",
      logout: "تسجيل الخروج",
    },
  },
  breadcrumbs: {
    home: "الصفحة الرئيسية",
    settings: "الإعدادات",
    gifts: "الهدايا",
    receivedGifts: "الهدايا المستلمة",
    sentGifts: "الهدايا المرسلة",
    profile: "الحساب",
    programs: "البرامج",
    preferences: "التفضيلات",
    programStatus: "حالة البرنامج",
    notifications: "الإشعارات",
    password: "كلمة المرور",
    personalInfo: "المعلومات الشخصية",
    currentProgram: "البرنامج الحالي",
    pastPrograms: "البرامج السابقة",
    allPrograms: "كل البرامج",
  },
  dialog: {
    cancel: "إلغاء",
    change: "تغيير",
    congratulations: "تهانينا! ",
    areYouSure: "هل أنت متأكد أنك تريد تغيير تفضيلاتك؟",
  },
  logoutDialog: {
    areYouSure: "هل أنت متأكد أنك تريد تسجيل الخروج من أيام الإيمان؟",
  },
  login: {
    title: "أيام الإيمان",
    description:
      "تركز أيام الإيمان على تقديم تجربة إيمانية للمسلمين الجدد أو المسلمين الموجودين، بحيث تبني أساسيات عقيدتهم لبنة لبنة حتى تترسخ أواصر الإسلام فيها من خلال ما يلي:",
    loginPhone: "تسجيل الدخول عن طريق الهاتف",
    loginEmail: "تسجيل الدخول عن طريق البريد الإلكتروني",
    form: {
      phoneNumber: {
        label: "رقم الهاتف",
        placeHolder: "أدخل رقم هاتفك",
      },
      email: {
        label: "البريد الإلكتروني",
        placeHolder: "أدخل بريدك الإلكتروني",
      },
      password: {
        label: "كلمة المرور",
        placeHolder: "أدخل كلمة المرور",
      },
      forgetPassword: "هل نسيت كلمة المرور؟",
      rememberMe: "تذكر حسابي",
      loginButton: "تسجيل الدخول",
      "didn'tSubscribe": "ليس لديك حساب؟",
      subscribeNow: "إنشاء حساب",
    },
  },
  otp: {
    confirmYourInfo: "قم بتأكيد معلوماتك",
    chooseToVerify:
      "يرجى اختيار البريد الإلكتروني أو رقم الهاتف للتحقق من حسابك لتعيين كلمة المرور الخاصة بك ومتابعة الاشتراك",
    chooseEmailOrPhone: "اختر البريد الإلكتروني أو رقم الهاتف",
    sendMailTo: "إرسال الرمز إلى البريد الإلكتروني",
    sendSMSTo: "إرسال الرمز إلى الرسائل القصيرة",
    confirm: "تأكيد",
    "didn'tSubscribe": "لم تشترك من قبل؟",
    subscribeNow: "إشترك الآن",
    enterOtp: {
      enterCode: "أدخل الرمز",
      messageSent: "تم إرسال رسالة إلى البريد الإلكتروني الخاص بك",
      enterToVerifyEmail: "أدخل الرمز للتحقق من حسابك لاستعادة كلمة المرور الخاصة بك",
      enterToVerifyPhone: "أدخل الرمز للتحقق من رقم هاتفك",
      didntReceive: "لم تتلق أي رمز حتى الآن؟",
      resend: "أعد إرسال الرمز",
      verify: "تأكيد",
    },
  },
  resetPassword: {
    header: "أعد تعيين كلمة السر",
    enterEmailOrPhone:
      "أدخل عنوان بريدك الإلكتروني أو رقم هاتفك وسنرسل لك رمز OTP لإعادة تعيين كلمة المرور الخاصة بك.",
    emailOrPhone: {
      label: "البريد الإلكتروني أو رقم الهاتف",
      placeholder: "أدخل بريدك الإلكتروني أو رقم هاتفك",
    },
    didntSubcribe: "لم تشترك من قبل؟",
    subscribeNow: "اشترك الآن",
    confirm: "تأكيد",
    formErrorMessages: {
      passwordLength: "يجب أن تتكون كلمة المرور من ثمانية أحرف على الأقل",
      mustContainLowerCase: "يجب أن تحتوي كلمة المرور على حرف صغير واحد على الأقل",
      mustContainUpperCase: "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
      mustContainNumber: "يجب أن تحتوي كلمة المرور على الاقل رقماً واحداً",
      mustContainSpecialChar: "يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
      mustMatchPassword: "يجب أن يتطابق الحقل مع كلمة المرور الجديدة",
    },
  },
  subscribe: {
    header: "اشترك في برامج أيام مع الإيمان واستقِ من معين التقوى والمعرفة.",
    description1:
      "يركز أيام الإيمان على تقديم تجربة إيمانية للمسلمين الجدد بحيث يبنون أساسيات عقيدتهم لبنة لبنة حتى تترسخ أواصر الإسلام فيها. ",
    description2: "لمزيد من التفاصيل",
    privacyPolicy: "سياسة الخصوصية",
    form: {
      dropdown: "اختر من القائمة",
      firstName: {
        label: "الاسم الأول",
        placeholder: "أدخل اسمك الأول",
      },
      lastName: {
        label: "اسم العائلة",
        placeholder: "أدخل اسمك الأخير",
      },
      email: {
        label: "البريد الإلكتروني",
        placeholder: "أدخل بريدك الإلكتروني",
      },
      mobilePhone: {
        label: "الهاتف المحمول",
        placeholder: "أدخل هاتفك المحمول",
      },
      chooseTheProgram: "اختر البرنامج",
      preferredSendingChannel: "قناة الإرسال المفضلة",
      customize: "تخصيص تجربة الرسائل الخاصة بك",
      tellUsAboutU: "أولاً، أخبرنا المزيد عنك",
      birthday: "عيد ميلاد",
      gender: "الجنس",
      islamicStatus: "حالتك الإسلامية",
      specialization: {
        label: "تخصصك",
        placeholder: "أدخل التخصص الخاص بك",
      },
      tellUsPreference: "ثانياً، أخبرنا كيف تفضل تلقي رسالتك",
      topicLength: "طول الموضوع",
      preferredLanguage: "لغتك المفضلة",
      preferredTime: "الوقت المفضل لديك",
      messageFrequency: "التردد الخاص برسائلك",
      frequencyHelperText: "للحصول على أفضل الفوائد، حدد جدولك اليومي",
      description: "سبب اشتراكك في نشراتنا الإخبارية",
      subscribeAndLogin: "يشترك",
      subscripitonBefore: "اشتركت في برنامج من قبل وليس لديك حساب؟",
      continueSubscription: "قم بإنشاء حساب هنا",
      weekDay: "يوم الأسبوع",
      sendingChannelHelper: "مطلوب بوت البداية",
    },
    formErrorMessages: {
      oneChannel: "يجب أن تختار قناة واحدة على الأقل",
    },
  },
  setPassword: {
    setPassword: "ضبط كلمة السر",
    pleaseSetPassword: "يرجى تعيين كلمة المرور الخاصة بحسابك",
    password: {
      label: "كلمة المرور",
      placeHolder: "إنشاء كلمة مرورك",
    },
    confirmPassword: {
      label: "تأكيد كلمة المرور",
      placeHolder: "قم بتأكيد كلمة المرور",
    },
    passwordRules: {
      mustContains: "كلمة المرور يجب أن تحتوي على:",
      eightChars: "تحتوي على 8 أحرف على الأقل",
      oneCapital: "تحتوي على حرف كبير واحد على الأقل",
      oneSmall: "تحتوي على حرف صغير واحد على الأقل",
      oneNumber: "يحتوي على رقم واحد على الأقل",
      special: "تحتوي على رموز خاصة",
    },
  },
  continueSubscription: {
    header: "قم بتأكيد معلوماتك",
    description: "يرجى إدخال رقم هاتفك أو بريدك الإلكتروني للتحقق من حسابك ومتابعة الاشتراك",
    emailOrPhone: {
      label: "البريد الإلكتروني أو رقم الهاتف",
      placeholder: "أدخل بريدك الإلكتروني أو رقم هاتفك",
    },
    confirm: "تأكيد",
    "didn'tSubscribe": "لم تقم بالاشتراك؟",
    subscribeNow: "اشترك الآن",
  },
  userProfile: {
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    phoneNumber: "رقم الهاتف",
    emailAddress: "عنوان البريد الإلكتروني",
    editProfile: "تعديل الملف الشخصي",
  },
  home: {
    latestGifts: "أحدث الهدايا الخاصة بك",
    giftsDetails: "شاهد تفاصيل الهدايا",
    changeProgramStatus: "تغيير حالة البرنامج",
    currentProgram: "البرنامج الحالي",
    currentProgramDetails: "انظر تفاصيل البرنامج",
    progress: "التقدم",
    currentDescription: "رحلتك في {{programName}} لقد أوشكت على الانتهاء من البرنامج. ",
    endDate: "تاريخ الانتهاء",
    messageMedium: "وسيلة الرسالة",
    subscribe: "اشتراك",
    discoverProgram: "اكتشف البرنامج",
  },
  gifts: {
    table: {
      toolbar: {
        searchPlaceHolder: "بحث",
        filters: "الفلاتر",
      },
      filtersDrawer: {
        program: "البرنامج",
        messageType: "نوع الرسالة",
        messageStatus: "حالة الرسالة",
        applyFilters: "تطبيق الفلاتر",
      },
      fields: {
        gift: "الهدية",
        sender: "المرسل",
        recipient: "المتلقي",
        program: "البرنامج",
        message: "الرسالة",
        type: "النوع",
        status: "الحالة",
        actions: "الإجراءات",
      },
      actions: {
        details: "التفاصيل",
        send: "إرسال",
      },
    },
    noGifts: "لم تتلق أو ترسل أي هدايا بعد.",
    sendGiftButton: "إرسال الهدية",
    giftPagination: {
      recipientForm: "نموذج المستلم",
      yourInformation: "معلوماتك",
      back: "السابق",
      next: "التالي",
      submit: "إرسال",
      cardDesign: "1- اختر تصميم البطاقة",
      cardColor: "2- اختر لون بطاقتك",
      changeProgram: "تغيير البرنامج",
      customizeYourGift: "تخصيص هديتك",
      sendingType: "نوع الإرسال",
    },
    form: {
      firstName: {
        label: "الاسم الأول",
        placeholder: "أدخل الاسم الأول للمستلم",
      },
      lastName: {
        label: "اسم العائلة",
        placeholder: "أدخل اسم عائلة المستلم",
      },
      email: {
        label: "البريد الإلكتروني",
        placeholder: "أدخل البريد الإلكتروني الخاص بالمستلم",
      },
      preferredSendingChannel: "قناة الإرسال المفضلة",
      preferredSendingDate: "تاريخ الإرسال المفضل",
      customizeMessage: "تخصيص تجربة رسالة المستلم",
      tellUsAboutU: "أولاً، أخبرنا المزيد عنك",
      dateOfBirth: "تاريخ الميلاد",
      gender: "الجنس",
      islamicStatus: "حالتك مع الإسلام",
      specialization: "تخصص المتلقي",
      tellUsPreference: "ثانياً، أخبرنا كيف تفضل تلقي رسالتك",
      messageFrequency: "تردد رسالة المتلقي",
      topicLength: "طول موضوع البرنامج",
      preferredLanguage: "اللغة المفضلة للبرنامج",
      preferredTime: "الوقت المفضل للمستلم",
      giftMessage: "رسالة الهدية",
      messageFrequencyHelperText: "للحصول على أفضل الفوائد، قم بتعيين جدول المستلم يوميًا",
      weekDay: "يوم الأسبوع",
      makeSure: "تأكد أن جميع الحقول المطلوبة قد تم ملؤها",
    },
    program: "البرنامج",
    filters: "المفلاتر",
    apply: "تطبيق الفلاتر",
    reset: "إعادة ضبط جميع الفلاتر",
    yourGift: "هديتك",
    recipientInformation: "معلومات المستلم",
    senderInformation: "معلومات المرسل",
    giftMessage: "رسالة الهدية",
    formErrorMessages: {
      onlyLetters: "يجب أن يحتوي هذا الحقل على أحرف فقط",
      selectOneChannel: "اختر قناة واحدة على الأقل",
      validEmail: "يجب أن يكون البريد الإلكتروني صالحاً",
    },
  },
  badges: {
    done: "أحسنت.",
    inProgress: "أكمل قراءة الرسالة للفوز بالشارة",
    noBadges: "لم تفز بأي شارات حتى الآن.",
    seePrograms: "شاهد جميع البرامج",
    win: "لقد فزت {{programName}} بشارة",
    notWin: "ممتاز! أنت على وشك نهاية البرنامج للفوز بشارة {{programName}}",
  },
  settings: {
    tabs: {
      preferences: "التفضيلات",
      programStatus: "حالة البرنامج",
      notifications: "الإشعارات",
      password: "كلمة المرور",
    },
    preferences: {
      editPreferences: "تعديل التفضيلات",
      personalPreferences: "التفضيلات الشخصية",
      messagePreferences: "تفضيلات الرسالة",
      preferenceItems: {
        dateOfBirth: "تاريخ الميلاد",
        gender: "الجنس",
        islamicStatus: "حالتك مع الإسلام",
        specialized: "التخصص",
        topicLength: "طول الموضوع",
        programLanaguage: "لغة البرنامج",
        messageMedium: "وسيلة الرسالة",
        messageTime: "وقت الرسالة",
        messageFrequency: "تردد الرسالة",
        weekDay: "يوم الأسبوع",
      },
    },
    programStatus: {
      change: "تغيير",
      inProgress: "في تَقَدم",
      status: {
        notParticipated: "أنت لم تشارك في أي برامج حتى الآن",
        restart: "سيتم إعادة تشغيل البرنامج الخاص بك",
        hold: "برنامجك معلق الآن وسيتم إعادة تشغيله في",
        completed: "تهانينا لقد أكملت البرنامج في",
        inProgress: "برنامجك قيد التقدم",
      },
    },
    notifications: {
      saveChanges: "حفظ التغييرات",
      newProgramNotification: "بدء برنامج جديد",
      finishProgramNotification: "إنهاء البرنامج",
      receivedGiftNotification: "حصلت على هدية جديدة",
      winningBadgeNotification: "شارة الفوز",
      deliveredGiftNotification: "تم تسليم الهدية",
    },
    password: {
      currentPassword: {
        title: "كلمة السر الحالية",
        placeHolder: "أدخل كلمة المرور الحالية",
      },
      newPassword: {
        title: "كلمة المرور الجديدة",
        placeHolder: "أدخل كلمة المرور الجديدة",
      },
      confirmPassword: {
        title: "تأكيد كلمة المرور",
        placeHolder: "قم بتأكيد كلمة المرور الجديدة",
      },
      cancel: "إلغاء",
      change: "حفظ",
      formErrorMessages: {
        passwordLength: "يجب أن تتكون كلمة المرور من ثمانية أحرف على الأقل",
        mustContainLowerCase: "يجب أن تحتوي كلمة المرور على حرف صغير واحد على الأقل",
        mustContainUpperCase: "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
        mustContainNumber: "يجب أن تحتوي كلمة المرور على الأقل رقماً واحداً",
        mustContainSpecialChar: "يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
        mustMatchPassword: "يجب أن تتطابق مع كلمة المرور الجديدة",
      },
    },
  },
  pastPrograms: {
    whatUSubcribeBefore: "ما قمت بالاشتراك فيه من قبل",
    restartProgram: "إعادة تشغيل البرنامج",
    subscribeAgain: "اشترك مرة أخرى",
    continueProgram: "مواصلة البرنامج",
    noPrograms: "لا توجد برامج لإظهارها هنا",
    areUSure: "هل أنت متأكد أنك تريد تغيير حالة البرنامج؟",
    formErrorMessages: {
      restartDate: "تاريخ إعادة البرنامج مطلوب",
    },
    editPreferences: "تعديل التفضيلات",
    cancel: "إلغاء",
  },
  changeCurrentProgramStatusDialog: {
    changeStatus: "تغيير حالة البرنامج إلى",
    editPreferences: "تحرير تفضيلات البرنامج الحالية",
    editPreferencesShort: "تعديل التفضيلات",
    subscribeDate: "حدد تاريخ الاشتراك الخاص بك",
    programStatus: "حالة البرنامج",
    restart: "إعادة التشغيل",
    holdLabel: "تعليق",
    unsubscribeLabel: "إلغاء الاشتراك",
    restartDate: "تاريخ إعادة التشغيل",
    cancel: "إلغاء",
    save: "حفظ",
    areUSure: "هل أنت متأكد أنك تريد تغيير حالة البرنامج؟",
    setYourRestartDate: "قم بتعيين تاريخ إعادة التشغيل",
    hold: {
      restartInDate: "إعادة التشغيل في تاريخ محدد",
    },
    unsubscribe: {
      reason: "السبب",
      notInterested: "أنا لست مهتماً بعد الآن",
      needsImprovement: "المحتوى يحتاج إلى تحسين",
      other: "أسباب أخرى",
    },
    restartContent: "سيتم إعادة تشغيل البرنامج الخاص بك في",
    holdContent: "تم تعليق البرنامج بنجاح",
    unsubscribeContent: "لقد قمت بإلغاء الاشتراك في البرنامج بنجاح.",
    programPreferences: "تغيير تفضيلات البرنامج",
    formErrorMessages: {
      restartDate: "تاريخ إعادة البرنامج مطلوب",
    },
  },
  changeCurrentProgramPreferencesDialog: {
    editCurrentPrgPrf: "تحرير تفضيلات البرنامج الحالية",
    areUSure: "هل أنت متأكد أنك تريد تغيير تفضيلات البرنامج؟",
    saveChanges: "حفظ التغييرات",
    cancel: "إلغاء",
    formErrorMessages: {
      oneChannel: "يجب أن تختار قناة واحدة على الأقل",
    },
  },
  subscribeToNewProgramDialog: {
    header: "اشترك في البرنامج",
    date: "تاريخ البدء",
    messageMedium: "وسيلة الرسالة",
    subscribe: "اشتراك",
    cancel: "إلغاء",
    areUSure: "هل أنت متأكد أنك تريد الاشتراك في هذا البرنامج؟",
    formErrorMessages: {
      oneChannel: "يجب أن تختار قناة واحدة على الأقل",
    },
  },
  changeGiftProgramDialog: {
    changeProgramHeader: "هل تريد تغيير البرنامج؟",
    chooseProgramHeader: "ًاختر برنامجا",
    cancel: "إلغاء",
    change: "تغيير",
    add: "إضافة",
  },
  giftingPopup: {
    title: "تهانينا! لقد تم تخصيص هديتك بشكل جيد وسيتم إرسالها كما تفضل.",
  },
  currentProgram: {
    description: "رحلتك في برنامج {{programName}} على وشك البدء.",
    startDate: "تاريخ البدء",
    endDate: "تاريخ الانتهاء",
    lastMessageOpen: "آخر رسالة مفتوحة في",
    frequency: "التكرار",
    time: "الوقت",
    messageMedium: "وسيلة الرسالة",
    editPreferences: "تعديل التفضيلات",
    messagesLeft: "الرسائل المتبقية",
    congratulations: "تهانينا",
    latestMessages: "أحدث الرسائل",
    noPrograms: "لا توجد برامج لإظهارها هنا",
  },
  allPrograms: {
    programName: "إسم البرنامج",
    programImage: "صورة البرنامج",
  },
  statistics: {
    recivedGiftCount: "مجموع الهدايا",
    totalMessagesCount: "إجمالي الرسائل",
    userWhatsappMessagesCount: "رسائل واتس اب",
    userEmailMessagesCount: "رسائل البريد الإلكتروني",
    userSmsMessagesCount: "رسائل SMS",
    userProgramsCount: "إجمالي البرامج",
    "Gifts count": "مجموع الهدايا",
    "Whatsapp message gifts count": "رسائل واتس اب",
    "Email message gifts count": "رسائل البريد الإلكتروني",
    "Sms message gifts count": "رسائل SMS",
  },
  telegramBot: {
    title: "بوت التيليجرام",
    description:
      "استخدم بوت تيليجرام، لتلقي الرسائل، وتلخيص الأفكار الرئيسية التي نقدمها في رسائل البريد الإلكتروني، حتى تكون خفيفة وسهلة للحصول على المعنى الأساسي للموضوع.",
    subtitle: "لبدء تلقي رسائل تلغرام, ستحتاج إلى البدء في استخدام الروبوت الخاص بنا.",
    subtitle1: "1. انقر على زر الاتصال برقية.",
    subtitle2: "2. ابدأ باستخدام بوت التلغرام الخاص بأيام مع الإيمان.",
    button: "الاتصال بتلغرام",
  },
  yes: "نعم",
  no: "لا",
  completedSuccessfully: "تم بنجاح",
  date: "تاريخ",
  messageChannel: "قناة الرسالة",
};

export default arabicJson;
