import React from 'react';
import FormProvider from 'src/components/hook-form/form-provider';
import { Stack, Typography, Divider, MenuItem } from '@mui/material';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import useApiServices from 'src/api/useApiServices';
import { API_URLS, ResDataType } from 'src/api/apiUrls';
import { Block } from 'src/components/Block';
import {
  RHFDatePicker,
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFTimePicker,
} from 'src/components/hook-form';
import { IBaseStatusDto } from 'src/types';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import Container from '@mui/material/Container';
import moment from 'moment';
import { useLocales } from 'src/locales';

const PersonalPreferencesForm = () => {
  const { useGetListApi } = useApiServices();

  const {
    data: genderType,
    isLoading: isTopicLengthLoading,
    error: topicLengthError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.GENDER_TYPE,
  });

  const {
    data: islamicStatus,
    isLoading: isLanguagesLoading,
    error: languagesError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.ISLAMIC_STATUS_TYPE,
  });

  const isLoading = isTopicLengthLoading || isLanguagesLoading;

  const errorMessage = topicLengthError || languagesError;

  const { t } = useLocales();

  return (
    <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={errorMessage}>
      <Container sx={{ py: 2 }}>
        <Stack spacing={2} textAlign="left">
          <Stack spacing={2}>
            <Block label={t(`settings.preferences.preferenceItems.gender`)}>
              <RHFSelect name="gender">
                {(genderType || [])?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label={t(`settings.preferences.preferenceItems.islamicStatus`)}>
              <RHFSelect name="islamicStatus">
                {(islamicStatus || [])?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label={t(`settings.preferences.preferenceItems.dateOfBirth`)}>
              <RHFDatePicker name="dateOfBirth" />
            </Block>
            <Block label={t(`settings.preferences.preferenceItems.specialized`)}>
              <RHFTextField name="specialized" />
            </Block>
          </Stack>
        </Stack>
      </Container>
    </AppLoadingAndErrorWrapper>
  );
};

export default PersonalPreferencesForm;
