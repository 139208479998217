import React, { useCallback, useState } from 'react';
import { Container, Card, Tabs, Tab } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import { alpha } from '@mui/material/styles';
import ChangePasswordView from 'src/sections/settings/change-password/view/ChangePasswordView';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import ProgramStatusView from 'src/sections/settings/change-program-status/ProgramStatusView';
import NotificationsView from 'src/sections/settings/notifications/NotificationsView';
import PreferencesView from 'src/sections/settings/preferences/PreferencesView';
import { useLocales } from 'src/locales';

const UserSettings = () => {
  const settings = useSettingsContext();
  const { t } = useLocales();

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);

  const [currentTabValue, setCurrentTabValue] = useState('preferences');

  const TABS = [
    {
      value: 'preferences',
      label: t('settings.tabs.preferences'),
      element: <PreferencesView />,
    },
    {
      value: 'programStatus',
      label: t('settings.tabs.programStatus'),
      element: <ProgramStatusView />,
    },
    {
      value: 'notifications',
      label: t('settings.tabs.notifications'),
      element: <NotificationsView />,
    },
    {
      value: 'password',
      label: t('settings.tabs.password'),
      element: <ChangePasswordView />,
    },
  ];

  const currentTab = TABS.filter((tab) => tab.value === currentTabValue)[0];

  const RenderedElement = TABS.filter((tab) => tab.value === currentTabValue)[0].element;

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <CustomBreadcrumbs
          links={[
            {
              name: t('breadcrumbs.home'),
              href: paths.dashboard.root,
            },
            {
              name: t('breadcrumbs.settings'),
              href: paths.dashboard.settings,
            },
            {
              name: t(`breadcrumbs.${currentTabValue}`),
            },
          ]}
        />
        <Tabs
          value={currentTabValue}
          onChange={handleChangeTab}
          sx={{
            px: 3,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        {RenderedElement}
      </Container>
    </>
  );
};

export default UserSettings;
