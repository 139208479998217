import { Box, Button, Container, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocales } from "src/locales";
import ChangePreferencesDialog from "src/sections/settings/preferences/change-preferences-dialog/ChangePreferencesDialog";
import { PERSONAL_PREFERENCES_LIST } from "./PreferencesLists";

type changeType = {
  name: string;
  label?: string;
  value?: string;
  options?: any;
  type?: string;
} | null;

const PreferencesView = () => {
  const settings = useSettingsContext();
  const { currentLang } = useLocales();
  const { useGetListApi } = useApiServices();
  const {
    data: userData,
    isLoading,
    error,
    isRefetching,
    refetch,
  } = useGetListApi<ResDataType["userProfile"]>({
    url: API_URLS.USER_PROFILE,
  });

  const preferencesDialog = useBoolean();
  const notifications = useForm();
  const [change, setChange] = useState<changeType>(null);
  const { t } = useLocales();

  const handleChangeAllPersonalPreferences = () => {
    setChange({ name: "personalPreferences" });
    preferencesDialog.onTrue();
  };

  useEffect(() => {
    refetch();
  }, [currentLang]);

  return (
    <>
      <Helmet>
        <title>Preferences</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <FormProvider methods={notifications}>
            {/* #region Personal preferences */}
            <Stack spacing={6}>
              <Stack spacing={2} width={{ xs: "100%", sm: "50%" }}>
                <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                  <Typography fontWeight={700}>
                    {t("settings.preferences.personalPreferences")}
                  </Typography>
                  <Typography fontWeight={700}>
                    <Button onClick={handleChangeAllPersonalPreferences}>
                      {t("settings.preferences.editPreferences")}
                    </Button>
                  </Typography>
                </Box>
                <Divider />
                <Stack spacing={2.5}>
                  {PERSONAL_PREFERENCES_LIST(userData!).map(e => (
                    <PreferenceItem key={e.name} {...e} />
                  ))}
                </Stack>
              </Stack>
              {/* #endregion Personal preferences */}
            </Stack>
          </FormProvider>
          <ChangePreferencesDialog
            open={preferencesDialog.value}
            onClose={preferencesDialog.onFalse}
            change={change}
          />
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default PreferencesView;

const PreferenceItem = ({ value, name }: { value: string | Date; name: string }) => {
  const { t } = useLocales();

  if (name === "weekDay" && !value) return <></>;
  let valueCustomized: any = "";
  if (typeof value === "string")
    valueCustomized = value?.slice(0, 1) + value?.toLowerCase().slice(1);
  else if (!value) valueCustomized = "";
  else valueCustomized = moment(value).format("YYYY-MM-DD");
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      flexDirection={{ xs: "column", md: "row" }}
      textAlign='end'
    >
      <Typography textAlign='start' fontWeight={500}>
        {t(`settings.preferences.preferenceItems.${name}`)}
      </Typography>
      <Typography>{valueCustomized || "No information provided"}</Typography>
    </Box>
  );
};
