import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Box, Typography, Button } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import useApiServices from "src/api/useApiServices";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import { useBoolean } from "src/hooks/use-boolean";
import ChangeCurrentProgramStatusDialog from "src/components/programs/current/ChangeCurrentProgramStatusDialog";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import moment from "moment";
import { useLocales } from "src/locales";

const ProgramStatusView = () => {
  const settings = useSettingsContext();
  const { useGetListApi } = useApiServices();
  const showChangeStatus = useBoolean();
  const { t } = useLocales();
  const {
    data: programData,
    isLoading,
    error,
  } = useGetListApi<ResDataType["currentProgram"]>({
    url: API_URLS.CURRENT_USER_PROGRAM,
  });

  const programStatus = programData?.status;

  let shownLabel = "";

  if (!programData) shownLabel = t("settings.programStatus.status.notParticipated");

  if (programStatus?.value.toLowerCase() === "restart")
    shownLabel = `${t("settings.programStatus.status.restart")} ${moment(programData?.startDate!)
      .format("YYYY-MM-DD h:mm:ss a")
      .toString()}`;

  if (programStatus?.value.toLowerCase() === "hold")
    shownLabel = `${t("settings.programStatus.status.hold")} ${programData?.startDate}`;

  if (programStatus?.value.toLowerCase() === "completed")
    shownLabel = `${t("settings.programStatus.status.completed")} ${moment(programData?.endDate)
      .format("YYYY-MM-DD [ at ] h:mm:ss a")
      .toString()}`;

  if (programStatus?.value.toLowerCase() === "in_progress")
    shownLabel = t("settings.programStatus.status.inProgress");

  return (
    <>
      <Helmet>
        <title>Program Status</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography>{shownLabel}</Typography>
            <Button
              disabled={!programData}
              variant='contained'
              color='primary'
              onClick={showChangeStatus.onTrue}
            >
              {t("settings.programStatus.change")}
            </Button>
          </Box>
          {showChangeStatus.value && (
            <ChangeCurrentProgramStatusDialog
              open={showChangeStatus.value}
              onClose={showChangeStatus.onFalse}
              programId={programData?.id!}
            />
          )}
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default ProgramStatusView;
