import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import LinearProgress from "@mui/material/LinearProgress";
import { Helmet } from "react-helmet-async";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/home/Header";
import Iconify from "src/components/iconify/iconify";
import Image from "src/components/image/image";
import { useSettingsContext } from "src/components/settings";
import { ASSETS_API } from "src/config-global";
import { useLocales } from "src/locales";
import { paths } from "src/routes/paths";
import NoBadges from "src/sections/badges/EmptyBadges";

const Badges = () => {
  const { t } = useLocales();
  const settings = useSettingsContext();
  const { useGetListApi } = useApiServices();

  const {
    data: badges,
    isLoading,
    error,
  } = useGetListApi<ResDataType["badges"]>({ url: API_URLS.BADGES });

  // TODO Add data type
  const getProgramsWithCategories = (data: any) => {
    const programMap: any = {};

    data?.items?.map((item: any) => {
      const programId = item?.programId;

      if (!programMap[programId]) {
        programMap[programId] = {
          programName: item.program.name,
          featureImage: item?.featureImage,
          badgeUser: item?.BadgeUser,
          categories: [],
        };
      }

      if (item.programCategory) {
        programMap[programId].categories.push(item.programCategory);
      }
    });

    const result = Object.values(programMap);

    return result;
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : "xl"}>
      <Helmet>Badges</Helmet>
      <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
        <CustomBreadcrumbs
          links={[
            {
              name: t("navbar.home"),
              href: paths.dashboard.root,
            },
            {
              name: t("navbar.badges"),
              href: paths.dashboard.settings,
            },
          ]}
        />
        <Header />
        <Box my={4} width={{ md: 720 }}>
          {badges?.items.length ? (
            <Stack spacing={3}>
              {getProgramsWithCategories(badges)?.map((badge: any, index) => (
                <Stack spacing={2}>
                  <Accordion sx={{ border: "1px solid #D3D3D3", borderRadius: "16px" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      sx={{ borderRadius: "16px", pl: 2 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <Image
                          src={`${ASSETS_API}${badge?.featureImage}`}
                          width={100}
                          height={65}
                          borderRadius={1}
                        />
                        <Typography
                          fontSize={24}
                          fontWeight={700}
                          display="flex"
                          alignItems="center"
                        >
                          {badge?.programName}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={3}>
                        <Typography variant="subtitle1" fontWeight={400}>
                          {badge?.badgeUser?.length > 0
                            ? `${t("badges.win", {
                                programName: badge?.programName,
                              })}`
                            : `${t("badges.notWin", {
                                programName: badge?.programName,
                              })}`}
                        </Typography>
                        <Divider />
                        <Stack spacing={2}>
                          {badge?.categories &&
                            badge?.categories?.map((category: any) => (
                              <Badge
                                featureImage={category?.featureImage}
                                programUserMessage={category?._count.programUserMessage!}
                                programTopics={category?._count.programTopics!}
                                name={category?.name}
                              />
                            ))}
                        </Stack>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              ))}
            </Stack>
          ) : (
            <NoBadges />
          )}
        </Box>
      </AppLoadingAndErrorWrapper>
    </Container>
  );
};

export default Badges;

const Badge = ({
  featureImage,
  programUserMessage,
  programTopics,
  name,
}: {
  featureImage: string;
  programUserMessage: number;
  programTopics: number;
  name: string;
}) => {
  const { t } = useLocales();
  const message: string =
    programTopics === programUserMessage ? t("badges.done") : t("badges.inProgress");

  const hasProgramTopics = programTopics !== null && programTopics !== undefined;
  const hasProgramUserMessages = programUserMessage !== null && programUserMessage !== undefined;

  return (
    <Stack spacing={2}>
      <Box display="flex" gap={2.5} sx={{ width: "100%" }}>
        {featureImage ? (
          <Image src={`${ASSETS_API}${featureImage}`} width={100} height={65} borderRadius={1} />
        ) : (
          <Box sx={{ bgcolor: "background.other", py: 3, px: 5, borderRadius: 1 }}>
            <Iconify icon="icon-park-twotone:five-star-badge" />
          </Box>
        )}

        <Stack spacing={0.5}>
          <Typography variant={"subtitle1"} fontWeight={700}>
            {name}
          </Typography>

          {hasProgramTopics && hasProgramUserMessages && (
            <Box display="flex" sx={{ width: "100%" }} alignItems="center" gap={3}>
              <LinearProgress
                value={(programUserMessage / programTopics) * 100}
                variant="determinate"
                sx={{ width: { md: 345, xs: 1 }, padding: "4px" }}
              />
              <Typography
                fontSize={13}
                fontWeight={400}
              >{`${programUserMessage}/${programTopics}`}</Typography>
            </Box>
          )}
          <Typography variant="subtitle2" fontWeight={400}>
            {message}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
