import { IBaseStatusDto } from "src/types";
import { BadgesDtoType } from "src/types/badges";
import { CurrentProgramDataType } from "src/types/currentProgram";
import { NotificationsDataType } from "src/types/notifications";
import { ProgramsDataType } from "src/types/programs-dropdown";
import { UserProfileType } from "src/types/userProfile";

export const API_URLS = {
  LOGIN_AUTH_USER: "auth-user/login",
  AUTH_USER_PROFILE: "auth-user/profile",
  USER_STATISTICS: "users/statistics",
  PROFILE: "auth-user/profile",
  SUBSCRIBE_PROGRAM: "auth-user/subscribe",
  RESET_PASSWORD: "auth-user/reset-password",
  CHANGE_PASSWORD: "auth-user/change-password",
  FORGOT_PASSWORD: "auth-user/forgot-password",
  TWO_FACTOR: "auth-user/two-factor-login",
  CONFIRM_EMAIL: "",
  // Notifications
  NOTIFICATIONS: "notifications",
  // Programs
  CURRENT_USER_PROGRAM: "program-users/in-progrss-program-with-count",
  LATEST_MESSAGES: "program-users/latest-message-program",
  PAST_PROGRAMS: "program-users",
  PROGRAMS_WITHOUT_AUTH: "programs",
  CHANGE_PROGRAM_STATUS: "program-users",
  SUGGESTED_UNSUBSCRIBED_PROGRAMS: "programs/random-unsubscribed-programs",
  ALL_PROGRAMS: "programs/list",
  // Gifts
  GIFTS: "gifts",
  RECEIVED_GIFTS: "gifts/received",
  SENT_GIFTS: "gifts/sent",
  LATEST_GIFTS: "gifts/latest-gift-received",
  GIFTS_STATISTICS: "gifts/statistic",
  // Profile
  USER_PROFILE: "auth-user/profile",
  // Enums
  MESSAGE_MEDIUM: "app/message-medium-type",
  GENDER_TYPE: "app/gender-type",
  ISLAMIC_STATUS_TYPE: "app/islamic-status",
  TOPIC_LENGTH: "app/topic-length",
  LANGUAGE_TYPE: "app/language-type",
  MESSAGES_FREQUENCY: "app/message-frequency-type",
  WEEK_DAYS: "app/week-days",
  GIFT_STATUS: "app/gift-status",
  SENDING_TYPE_GIFT: "app/sending-type-gift",
  // Badges
  BADGES: "badges",
  MARK_AS_READ: "notifications/mark-as-read"
};

// Define a type that represents the response type of each endpoint
export type ResDataType = {
  dropDowns: IBaseStatusDto[];
  programs: ProgramsDataType;
  userProfile: UserProfileType;
  currentProgram: CurrentProgramDataType;
  notifications: NotificationsDataType;
  badges: BadgesDtoType;
};
