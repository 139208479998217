import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const ForgetPasswordPage = lazy(() => import('src/pages/auth/jwt/forgetPassword'));

// Subscribe to the program
const SubscribePage = lazy(() => import('src/pages/auth/subscribe/subscribe'));

// Set new password after subscribe
const SetPasswordPage = lazy(() => import('src/pages/auth/subscribe/setPassword'));

// Confirm info
const ConfirmInformationPagePage = lazy(
  () => import('src/pages/auth/subscribe/confirmInformation')
);

// OTP Confirmation
const OtpConfirmationPage = lazy(() => import('src/pages/auth/subscribe/OtpConfirmation'));

// Continue Subscription
const ContinueSubscriptionPage = lazy(
  () => import('src/pages/auth/subscribe/continueSubscription/continueSubscription')
);

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Outlet />
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: <JwtLoginPage />,
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with Minimal">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'forgetPassword',
      element: <ForgetPasswordPage />,
    },
    {
      path: 'subscribe',
      element: <SubscribePage />,
    },
    {
      path: 'setPassword',
      element: <SetPasswordPage />,
    },
    {
      path: 'confirmInformation',
      element: <ConfirmInformationPagePage />,
    },
    {
      path: 'otp',
      element: <OtpConfirmationPage />,
    },
    {
      path: 'continueSubscription',
      element: <ContinueSubscriptionPage />,
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
