// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: "https://mui.com/store/items/minimal-dashboard/",
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgetPassword: `${ROOTS.AUTH}/jwt/forgetPassword`,
      subscribe: `${ROOTS.AUTH}/jwt/subscribe`,
      setPassword: `${ROOTS.AUTH}/jwt/setPassword`,
      confirmInformation: `${ROOTS.AUTH}/jwt/confirmInformation`,
      otpConfirmation: `${ROOTS.AUTH}/jwt/otp`,
      continueSubscription: `${ROOTS.AUTH}/jwt/continueSubscription`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    programs: {
      current: `${ROOTS.DASHBOARD}/programs`,
      past: `${ROOTS.DASHBOARD}/programs/past`,
      all: `${ROOTS.DASHBOARD}/programs/all`,
    },
    gifts: {
      received: `${ROOTS.DASHBOARD}/gifts`,
      sent: `${ROOTS.DASHBOARD}/gifts/sent`,
      customize: `${ROOTS.DASHBOARD}/gifts/customize/?programId=:id`,
    },
    settings: `${ROOTS.DASHBOARD}/settings`,
    badges: `${ROOTS.DASHBOARD}/badges`,
  },
};
