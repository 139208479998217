import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/material';
import React from 'react';

interface IAppLoadingButton {
  label: string;
  isLoading: boolean;
  onClick?: any;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: SxProps;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small'
}

const AppLoadingButton: React.FC<IAppLoadingButton> = (props) => {
  const {
    label,
    isLoading,
    onClick,
    variant = 'contained',
    color = 'primary',
    sx,
    fullWidth = true,
    disabled = false,
    size
  } = props;

  return (
    <LoadingButton
      fullWidth={fullWidth}
      disabled={disabled}
      sx={{
        fontWeight: '700px',
        fontSize: { md: '16px', xs: '12px' },
        color: 'black',
        padding: { md: '16px 28px', xs: '10px' },
        border: '2px solid black',
        borderRadius: '70px',
        lineHeight: '24px',
        textTransform: 'capitalize',
        ...sx,
      }}
      color={color}
      size={size}
      variant={variant}
      type="submit"
      onClick={onClick}
      loading={isLoading}
    >
      {label}
    </LoadingButton>
  );
};

export default AppLoadingButton;
