import * as Yup from "yup";
import moment from "moment";
import { ResDataType } from "src/api/apiUrls";

export const PERSONAL_PREFERENCES_LIST = (userData: ResDataType["userProfile"]) => [
  {
    label: "Date of birth",
    name: "dateOfBirth",
    value: moment(userData?.dateOfBirth).toDate(),
    type: "date",
  },
  {
    label: "Gender",
    name: "gender",
    value: userData?.gender?.label,
    type: "dropdown",
    options: "GENDER_TYPE",
  },
  {
    label: "Islamic status",
    name: "islamicStatus",
    value: userData?.islamicStatus?.label,
    type: "dropdown",
    options: "ISLAMIC_STATUS_TYPE",
  },
  {
    label: "Specialized",
    name: "specialized",
    value: userData?.specialized,
    type: "text",
  },
];

export const personalPreferencesDefaultValues = (userData: ResDataType["userProfile"]) => ({
  dateOfBirth: moment(userData?.dateOfBirth).toDate(),
  gender: userData?.gender.value,
  islamicStatus: userData?.islamicStatus.value,
  specialized: userData?.specialized,
});

export const personalPreferencesFormSchema = Yup.object().shape({
  dateOfBirth: Yup.date().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  islamicStatus: Yup.string().required("Islamic status is required"),
  specialized: Yup.string().required("Specialized is required"),
});
