import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import useSchemas from "src/hooks/use-schemas";
import { useLocales } from "src/locales";
import { defaultValues } from "../change-password-schema";
import ChangePasswordPopup from "../ChangePasswordPopup";

const ChangePasswordView = () => {
  const settings = useSettingsContext();
  const { usePostApi } = useApiServices();
  const { t, currentLang } = useLocales();
  const dialog = useBoolean();
  const { ChangePasswordSchema } = useSchemas();

  const password = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    defaultValues: defaultValues,
  });

  const { mutate } = usePostApi({
    url: API_URLS.CHANGE_PASSWORD,
    onSuccess: dialog.onTrue,
    withSuccessNotistack: false,
  });

  const { handleSubmit, watch } = password;

  const onSubmit = handleSubmit(async (data: any) => {
    mutate(data);
  });

  const INPUT_FIELDS = [
    {
      label: t("settings.password.currentPassword.title"),
      placeholder: t("settings.password.currentPassword.placeHolder"),
      name: "currentPassword",
      showPassword: useBoolean(),
    },
    {
      label: t("settings.password.newPassword.title"),
      placeholder: t("settings.password.newPassword.placeHolder"),
      name: "password",
      showPassword: useBoolean(),
    },
    {
      label: t("settings.password.confirmPassword.title"),
      placeholder: t("settings.password.confirmPassword.placeHolder"),
      name: "confirmPassword",
      showPassword: useBoolean(),
    },
  ];

  useEffect(() => {
    /*
    Reset the form with existing values when the language
    is changed so error messages will be shown in the new
    language when the submit button is clicked, otherwise
    they will not be translated by default
    */
    password.reset(password.watch());
  }, [currentLang]);

  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <FormProvider methods={password} onSubmit={onSubmit}>
          <Stack spacing={2.5} width={{ xs: "100%", md: "50%" }}>
            {INPUT_FIELDS.map(field => (
              <Block key={field.name} label={field.label}>
                <RHFTextField
                  type={field.showPassword.value ? "text" : "password"}
                  size='medium'
                  name={field.name}
                  label={field.placeholder}
                  InputProps={{
                    sx: { borderRadius: "16px" },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={field.showPassword.onToggle} edge='end'>
                          {field.showPassword.value ? (
                            <VisibilityOutlinedIcon fontSize='small' />
                          ) : (
                            <VisibilityOffOutlinedIcon fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Block>
            ))}
            {/* Password conditions */}
            <Stack>
              <Typography fontSize={16} fontWeight={500}>
                {t("setPassword.passwordRules.mustContains")}
              </Typography>
              <FormControl component='fieldset'>
                <RadioGroup sx={{ fontSize: "50px", pointerEvents: "none" }}>
                  <FormControlLabel
                    label={t("setPassword.passwordRules.eightChars")}
                    control={<Radio checked={watch().password?.length > 8} />}
                  />
                  <FormControlLabel
                    label={t("setPassword.passwordRules.oneCapital")}
                    control={<Radio checked={/[A-Z]/.test(watch().password)} />}
                  />
                  <FormControlLabel
                    label={t("setPassword.passwordRules.oneSmall")}
                    control={
                      <Radio
                        checked={
                          watch().password !== undefined && /^.*[a-z].*$/.test(watch().password)
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("setPassword.passwordRules.oneNumber")}
                    control={<Radio checked={/\d/.test(watch().password)} />}
                  />
                  <FormControlLabel
                    label={t("setPassword.passwordRules.special")}
                    control={<Radio checked={/[!@#$%^&*]/.test(watch().password)} />}
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Box display='flex' gap={2} flexDirection={{ xs: "column", sm: "row" }}>
              <Button type='submit' variant='contained' color='primary'>
                {t("settings.password.change")}
              </Button>
            </Box>
          </Stack>
        </FormProvider>
        <ChangePasswordPopup dialog={dialog} />
      </Container>
    </>
  );
};

export default ChangePasswordView;
