import { m } from "framer-motion";
// @mui
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// routes
import { useRouter } from "src/routes/hook";
// auth
import { useAuthContext } from "src/auth/hooks";
// components
import { varHover } from "src/components/animate";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useBoolean } from "src/hooks/use-boolean";
import LogoutPopup from "../dashboard/LogoutPopup";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover () {
  const router = useRouter();
  const { t } = useLocales();
  const { logout, user } = useAuthContext();
  const popover = usePopover();
  const dialog = useBoolean();

  const OPTIONS = [
    {
      label: t("header.profilePopover.personalInfo"),
      linkTo: `${paths.dashboard.profile}`,
      icon: <PersonIcon fontSize='small' color='primary' />,
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace("/");
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: theme => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: theme =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.photoURL}
          alt={user?.firstName}
          sx={{
            width: 36,
            height: 36,
            border: theme => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant='subtitle2' noWrap>
            {user?.firstName}
          </Typography>

          <Typography variant='body2' sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map(option => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={dialog.onTrue}
          sx={{ m: 1, fontWeight: "fontWeightBold", color: "error.main" }}
        >
          {t("header.profilePopover.logout")}
        </MenuItem>
      </CustomPopover>
      <LogoutPopup dialog={dialog} />
    </>
  );
}
