import * as Yup from "yup";

// ----------------------------------------------------------------------

export type ChangePasswordFormType = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export const defaultValues: ChangePasswordFormType = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};