import { useFormContext, Controller } from "react-hook-form";
// @mui
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Typography } from "@mui/material";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  isRequired?: boolean;
  label?: string;
};

export default function RHFTextField({
  name,
  helperText,
  isRequired = true,
  label,
  type,
  sx,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            id={name}
            fullWidth
            type={type}
            InputProps={{ sx: { borderRadius: 2, ...sx } }}
            value={type === "number" && field.value === 0 ? "" : field.value}
            onChange={(event) => {
              if (type === "number") {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
            label={label}
          />
        )}
      />
    </>
  );
}
