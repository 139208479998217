import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Box, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocales } from "src/locales";
import {
  personalPreferencesDefaultValues,
  personalPreferencesFormSchema,
} from "../PreferencesLists";
import MessagePreferencesForm from "./MessagePreferencesForm";
import PersonalPreferencesForm from "./PersonalPreferencesForm";

type changeType = {
  name: string;
  label?: string;
  value?: string;
  options?: string;
  type?: string;
} | null;

interface IChangePreferencesDialogStatus {
  open: boolean;
  onClose: VoidFunction;
  change: changeType;
}

const ChangePreferencesDialog: React.FC<IChangePreferencesDialogStatus> = props => {
  const { open, onClose, change: change } = props;
  const { usePostApi, useGetListApi } = useApiServices();
  const { t } = useLocales();
  const confirm = useBoolean();
  const success = useBoolean();
  const [dataToSend, setDataToSend] = useState<any>();

  const { mutate, isLoading } = usePostApi({
    url: API_URLS.AUTH_USER_PROFILE,
    withSuccessNotistack: false,
    onSuccess: () => {
      success.onTrue();
    },
  });

  const {
    data: userData,
    isLoading: isUserDataLoading,
    error,
  } = useGetListApi<ResDataType["userProfile"]>({
    url: API_URLS.AUTH_USER_PROFILE,
  });

  const methods = useForm({
    defaultValues: {
      ...personalPreferencesDefaultValues(userData!),
    },
    resolver: yupResolver(personalPreferencesFormSchema),
  });

  const onSubmit = useCallback(async (data: any) => {
    data.dateOfBirth = moment(data.dateOfBirth).toDate();
    data.messageTime = data.messageTime;
    if (!(data.messageFrequency === "weekly")) delete data.weekDay;
    setDataToSend(data);
    confirm.onTrue();
  }, []);

  const handleMutate = (dataToMutate: any) => {
    dataToMutate.messageTime = moment(dataToMutate.messageTime).format("HH:mm:ssZZ");
    mutate(dataToSend);
  };

  return (
    <>
      <Dialog
        open={open && !confirm?.value}
        onClose={onClose}
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid
                  item
                  container
                  spacing={1}
                  sx={{ display: "flex", justifyContent: "center", mb: 2 }}
                >
                  {change?.name === "messagePreferences" ? (
                    <MessagePreferencesForm />
                  ) : change?.name === "personalPreferences" ? (
                    <PersonalPreferencesForm />
                  ) : null}
                  <Grid item xs={6}>
                    <AppButton
                      label={t("dialog.cancel")}
                      color='secondary'
                      onClick={onClose}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton label={t("dialog.change")} isLoading={isLoading} />
                  </Grid>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        open={confirm.value && !success.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason == "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t("dialog.areYouSure")}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth={true}
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading}
              onClick={() => handleMutate(dataToSend)}
            />
          </>
        }
      />

      <ConfirmDialog
        open={success?.value && confirm?.value}
        onClose={onClose}
        close={() => {
          success.onFalse();
          confirm.onFalse();
          onClose();
        }}
        content={t("dialog.congratulations")}
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
};

export default ChangePreferencesDialog;
