import React from 'react';
import { Stack, MenuItem } from '@mui/material';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import useApiServices from 'src/api/useApiServices';
import { API_URLS } from 'src/api/apiUrls';
import { Block } from 'src/components/Block';
import { RHFMultiSelect, RHFSelect, RHFTimePicker } from 'src/components/hook-form';
import { IBaseStatusDto } from 'src/types';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import Container from '@mui/material/Container';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useLocales } from 'src/locales';

const MessagePreferencesForm = () => {
  const { useGetListApi } = useApiServices();

  const {
    data: topicLength,
    isLoading: isTopicLengthLoading,
    error: topicLengthError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.TOPIC_LENGTH,
  });

  const {
    data: messageMedium,
    isLoading: isMessageMediumLoading,
    error: messageMediumError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.MESSAGE_MEDIUM,
  });

  const {
    data: languages,
    isLoading: isLanguagesLoading,
    error: languagesError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.LANGUAGE_TYPE,
  });

  const {
    data: weekDays,
    isLoading: isWeekDaysLoading,
    error: weekDaysError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.WEEK_DAYS,
  });

  const {
    data: messageFrequency,
    isLoading: isMessageFrequencyLoading,
    error: messageFrequencyError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.MESSAGES_FREQUENCY,
  });

  const isLoading =
    isTopicLengthLoading ||
    isLanguagesLoading ||
    isWeekDaysLoading ||
    isMessageFrequencyLoading ||
    isMessageMediumLoading;

  const errorMessage =
    topicLengthError ||
    languagesError ||
    weekDaysError ||
    messageFrequencyError ||
    messageMediumError;

  const { watch, control } = useFormContext();
  const { t } = useLocales();

  return (
    <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={errorMessage}>
      <Container sx={{ py: 2 }}>
        <Stack spacing={2} textAlign="left">
          <Stack spacing={2}>
            <Block label={t(`settings.preferences.preferenceItems.topicLength`)}>
              <RHFSelect label="Choose" name="topicLength">
                {(topicLength || [])?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label={t(`settings.preferences.preferenceItems.programLanaguage`)}>
              <RHFSelect label="Choose" name="programLanaguage">
                {(languages || [])?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
          </Stack>
          <Stack spacing={2}>
            <Block label={t(`settings.preferences.preferenceItems.messageTime`)}>
              <Controller
                name="messageTime"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TimePicker
                      {...field}
                      value={field.value || null}
                      minutesStep={30}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderRadius: '16px',
                          },
                        },
                      }}
                      slotProps={{
                        textField: {
                          error: !!error,
                        },
                      }}
                    />
                  );
                }}
              />
            </Block>
            <Block label={t(`settings.preferences.preferenceItems.messageFrequency`)}>
              <RHFSelect label="Choose" name="messageFrequency">
                {(messageFrequency || [])?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
          </Stack>
          {watch().messageFrequency?.toLowerCase() === 'weekly' && (
            <Stack>
              <Block label={t(`settings.preferences.preferenceItems.weekDay`)}>
                <RHFSelect label="Choose" name="weekDay">
                  {(weekDays || [])?.map((day) => (
                    <MenuItem key={day?.value} value={day?.value}>
                      {day?.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Block>
            </Stack>
          )}
          <Block label={t(`settings.preferences.preferenceItems.messageMedium`)}>
            <RHFMultiSelect
              checkbox
              name="messageMedium"
              label="Choose"
              options={messageMedium || []}
            />
          </Block>
        </Stack>
      </Container>
    </AppLoadingAndErrorWrapper>
  );
};

export default MessagePreferencesForm;
