// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

import "src/locales/i18n";

// ----------------------------------------------------------------------
// routes
import Router from "src/routes/sections";
// theme
import ThemeProvider from "src/theme";
// hooks
import { useScrollToTop } from "src/hooks/use-scroll-to-top";
// components
import ProgressBar from "src/components/progress-bar";
import MotionLazy from "src/components/animate/motion-lazy";
import { SettingsDrawer, SettingsProvider, useSettingsContext } from "src/components/settings";
// auth
import { AuthConsumer, AuthProvider } from "src/auth/context/jwt";
//
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// react-slick
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SnackbarProvider from "src/components/snackbar/snackbar-provider";
import { useLocales } from "./locales";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "./routes/hook";

// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  useScrollToTop();
  const locales = useLocales();
  const { i18n } = useTranslation();
  const settings = useSettingsContext();

  const searchParams = useSearchParams();
  const lang = searchParams.get("lang");

  useEffect(() => {
    if (lang) {
      lang && localStorage.setItem("i18nextLng", lang);
      i18n.changeLanguage(lang);
      settings.onChangeDirectionByLang(lang);
      history.replaceState(null, "", document.URL.split("?")[0]);
    }
  }, [lang]);

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <SettingsDrawer />
              <ProgressBar />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AuthConsumer>
                  <Router />
                </AuthConsumer>
              </LocalizationProvider>
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  );
}
