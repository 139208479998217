import { m } from "framer-motion";
import { useEffect, useState } from "react";
// @mui
import { List, Stack, Badge, IconButton, Typography, Button, Divider } from "@mui/material";
// hooks
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
// _mock
import { _notifications } from "src/_mock";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { varHover } from "src/components/animate";
//
import NotificationItem from "./notification-item";
import useApiServices from "src/api/useApiServices";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import CustomPopover from "src/components/custom-popover/custom-popover";
import { usePopover } from "src/components/custom-popover";
import { useLocales } from "src/locales";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

export default function NotificationsPopover () {
  const { t } = useLocales();
  const popover = usePopover();

  const { useGetListApi } = useApiServices();

  const smUp = useResponsive("up", "sm");

  const { data: notificationsArr } = useGetListApi<ResDataType["notifications"]>({
    url: API_URLS.NOTIFICATIONS,
  });

  const [notifications, setNotifications] = useState(notificationsArr?.items);

  useEffect(() => {
    setNotifications(notificationsArr?.items);
  }, [notificationsArr?.items]);

  const renderHead = (
    <Stack direction='row' alignItems='center' sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        {t("header.notificationsPopover.title")}
      </Typography>

      {!smUp && (
        <IconButton onClick={popover.onClose}>
          <Iconify icon='mingcute:close-line' />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications?.map(notification => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        color={popover.open ? "primary" : "default"}
        onClick={popover.onOpen}
      >
        <Badge badgeContent={0} color='error'>
          <Iconify icon='solar:bell-bing-bold-duotone' width={24} />
        </Badge>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 320 }}>
        {renderHead}
        <Scrollbar sx={{ height: 320 }}>{renderList}</Scrollbar>
        <Divider />
        <Button fullWidth sx={{ borderRadius: "0px 0px 10px 10px;" }}>
          <Link style={{color: '#FFAB8A', textDecoration: 'none'}} to={`/dashboard/notifications`}>{t("header.notificationsPopover.seeAll")}</Link>
        </Button>
      </CustomPopover>
    </>
  );
}
