import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Box, Grid, MenuItem, Stack, Switch, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, {
  RHFDatePicker,
  RHFMultiSelect,
  RHFSelect,
  RHFTimePicker,
} from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import useSchemas from "src/hooks/use-schemas";
import { useLocales } from "src/locales";
import { IBaseStatusDto } from "src/types";
import { CurrentProgramDataType } from "src/types/currentProgram";
import { IChangeCurrentProgramStatusDto } from "src/types/programs";
import { ProgramDetailsDtoType } from "src/types/programs-dropdown";

interface IChangeCurrentProgramStatusDialogProps {
  open: boolean;
  onClose: VoidFunction;
  programId: number;
}

const ChangeCurrentProgramStatusDialog: React.FC<
  IChangeCurrentProgramStatusDialogProps
> = props => {
  // #region States
  const { open, onClose } = props;
  const { usePatchApi, useGetListApi, useGetItemApi } = useApiServices();
  const { t } = useLocales();
  const confirm = useBoolean();
  const success = useBoolean();
  const showPreferences = useBoolean();
  const showPreferencesButton = useBoolean();
  const { ChangeCurrentProgramStatusSchema } = useSchemas();
  const [dataToSend, setDataToSend] = useState<IChangeCurrentProgramStatusDto>();
  // #endregion States

  // #region Form
  const methods = useForm<IChangeCurrentProgramStatusDto>({
    resolver: yupResolver<IChangeCurrentProgramStatusDto>(
      ChangeCurrentProgramStatusSchema(showPreferences.value)
    ),
  });

  console.log(methods.formState.errors);

  const {
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = methods;
  // #endregion Form

  // #region Services
  const {
    data: messageFrequency,
    isLoading: isMessageFrequencyLoading,
    error: messageFrequencyError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.MESSAGES_FREQUENCY,
  });

  const {
    data: weekDays,
    isLoading: isWeekDaysLoading,
    error: weekDaysError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.WEEK_DAYS,
  });

  const {
    data: prgData,
    isLoading: isPrgDataLoading,
    error: prgDataError,
  } = useGetListApi<CurrentProgramDataType>({
    url: API_URLS.CURRENT_USER_PROGRAM,
    onSuccess: (data: CurrentProgramDataType) => {
      methods.setValue(
        "messageMedium",
        data?.program.messageMedium.map(e => e.value)
      );
      methods.setValue("programLanaguage", data?.programLanaguage.value);
      methods.setValue("topicLength", data?.topicLength.value);
      methods.setValue("messageFrequency", data?.messageFrequency.value);
      methods.setValue("messageTime", new Date(data?.messageTime));
    },
    enabled: open,
  });

  const { mutate, isLoading } = usePatchApi({
    url: API_URLS.CHANGE_PROGRAM_STATUS,
    id: prgData?.id!,
    urlAfterSuccess: API_URLS.CURRENT_USER_PROGRAM,
    withSuccessNotistack: false,
    onSuccess: () => {
      success.onTrue();
    },
  });

  const {
    data: currentPrgDetails,
    isLoading: isCurrentPrgLoading,
    error: currentPrgError,
  } = useGetItemApi<ProgramDetailsDtoType>({
    url: API_URLS.PROGRAMS_WITHOUT_AUTH,
    id: prgData?.program.slug,
  });
  // #endregion Services

  // #region Handlers
  const onSubmit = useCallback(async (data: IChangeCurrentProgramStatusDto) => {
    data.messageTime = moment(data.messageTime).format("HH:mm:ssZZ") as unknown as Date;
    setDataToSend(data);
    confirm.onTrue();
  }, []);

  const handleEditPrfs = () => {
    if (watch().startDate || !watch().hasRestartDate) {
      showPreferences.onTrue();
      showPreferencesButton.onFalse();
    } else {
      setError("startDate", {
        message: t("changeCurrentProgramStatusDialog.formErrorMessages.restartDate"),
      });
    }
  };
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    if (watch().startDate) {
      clearErrors("startDate");
    }
  }, [watch().startDate]);
  // #endregion useEffect

  // #region Sections
  // Restart section
  const RestartSection = () => {
    return (
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography>{t("changeCurrentProgramStatusDialog.programPreferences")}</Typography>
            <Switch
              checked={showPreferencesButton.value}
              onChange={showPreferencesButton.onToggle}
            />
          </Stack>
          <Block label={t("changeCurrentProgramStatusDialog.restartDate")}>
            <RHFDatePicker name='startDate' minDate={new Date()} />
          </Block>
        </Stack>
      </Grid>
    );
  };

  // Hold section
  const HoldSection = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography fontWeight={500} fontSize={14} pb={0.5}>
            {t("changeCurrentProgramStatusDialog.hold.restartInDate")}
          </Typography>
          <RHFSelect name='hasRestartDate' defaultValue={0}>
            <MenuItem key={1} value={1}>
              {t("yes")}
            </MenuItem>
            <MenuItem key={2} value={0}>
              {t("no")}
            </MenuItem>
          </RHFSelect>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography>{t("changeCurrentProgramStatusDialog.programPreferences")}</Typography>
            <Switch
              checked={showPreferencesButton.value}
              onChange={showPreferencesButton.onToggle}
            />
          </Stack>
        </Grid>
        {watch().hasRestartDate ? (
          <Grid item xs={12}>
            <Block label={t("changeCurrentProgramStatusDialog.restartDate")}>
              <RHFDatePicker name='startDate' minDate={new Date()} />
            </Block>
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  // Unsubscribe section
  const UnsubscribeSection = () => {
    return (
      <Grid item xs={12}>
        <Typography fontWeight={500} fontSize={14} pb={0.5}>
          {t("changeCurrentProgramStatusDialog.unsubscribe.reason")}
        </Typography>
        <RHFSelect name='reason'>
          <MenuItem key={1} value={"I am not interested any more"}>
            {t("changeCurrentProgramStatusDialog.unsubscribe.notInterested")}
          </MenuItem>
          <MenuItem key={2} value={"The content need improvement"}>
            {t("changeCurrentProgramStatusDialog.unsubscribe.needsImprovement")}
          </MenuItem>
          <MenuItem key={2} value={"Other"}>
            {t("changeCurrentProgramStatusDialog.unsubscribe.other")}
          </MenuItem>
        </RHFSelect>
      </Grid>
    );
  };
  // #endregion Sections

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={() => {
          onClose();
          showPreferences.onFalse();
          showPreferencesButton.onFalse();
        }}
        PaperProps={{ sx: { borderRadius: "4px" } }}
        fullWidth
      >
        <AppLoadingAndErrorWrapper
          isLoading={
            isMessageFrequencyLoading ||
            isWeekDaysLoading ||
            isCurrentPrgLoading ||
            isPrgDataLoading
          }
          errorMessage={messageFrequencyError || weekDaysError || currentPrgError || prgDataError}
        >
          <DialogContent>
            <Box p={{ md: 8, xs: 3 }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography fontSize={28} fontWeight={500} align='center'>
                      {showPreferences.value
                        ? t("changeCurrentProgramStatusDialog.editPreferences")
                        : t("changeCurrentProgramStatusDialog.changeStatus")}
                    </Typography>
                  </Grid>
                  {!showPreferences.value && (
                    <Grid
                      item
                      container
                      sx={{ display: "flex", justifyContent: "center" }}
                      direction='column'
                    >
                      <Typography fontWeight={500} fontSize={14} pb={0.5}>
                        {t("changeCurrentProgramStatusDialog.programStatus")}
                      </Typography>
                      <RHFSelect name='status'>
                        <MenuItem key={1} value={"RESTART"}>
                          {t("changeCurrentProgramStatusDialog.restart")}
                        </MenuItem>
                        <MenuItem key={2} value={"HOLD"}>
                          {t("changeCurrentProgramStatusDialog.holdLabel")}
                        </MenuItem>
                        <MenuItem key={3} value={"UN_SUBSCRIBE"}>
                          {t("changeCurrentProgramStatusDialog.unsubscribeLabel")}
                        </MenuItem>
                      </RHFSelect>
                    </Grid>
                  )}
                  {watch().status && !showPreferences.value && (
                    <Grid
                      item
                      container
                      spacing={1}
                      sx={{ display: "flex", justifyContent: "center", mb: 2 }}
                    >
                      {watch().status == "RESTART" ? (
                        <RestartSection />
                      ) : watch().status == "HOLD" ? (
                        <HoldSection />
                      ) : watch().status == "UN_SUBSCRIBE" ? (
                        <UnsubscribeSection />
                      ) : (
                        ""
                      )}
                    </Grid>
                  )}
                  {showPreferences.value && (
                    <Grid
                      container
                      xs={12}
                      columnSpacing={{ xs: 0, md: 1 }}
                      rowSpacing={2}
                      sx={{ my: 2 }}
                    >
                      <Grid item xs={12} md={6}>
                        <Block label={t(`settings.preferences.preferenceItems.messageMedium`)}>
                          <RHFMultiSelect
                            checkbox
                            defaultValue={[]}
                            name='messageMedium'
                            label='Choose'
                            options={currentPrgDetails?.messageMedium || []}
                          />
                        </Block>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Block label={t(`settings.preferences.preferenceItems.topicLength`)}>
                          <RHFSelect name='topicLength'>
                            {currentPrgDetails?.topicLength?.map((length: any) => (
                              <MenuItem key={length.value} value={length.value}>
                                {length.label}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                        </Block>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Block label={t(`settings.preferences.preferenceItems.programLanaguage`)}>
                          <RHFSelect name='programLanaguage'>
                            {currentPrgDetails?.language?.map((lang: any) => (
                              <MenuItem key={lang.value} value={lang.value}>
                                {lang.label}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                        </Block>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Block label={t(`settings.preferences.preferenceItems.messageTime`)}>
                          <RHFTimePicker name='messageTime' formatValue={false} minutesStep={30} />
                        </Block>
                      </Grid>
                      <Grid item xs={12}>
                        <Block label={t(`settings.preferences.preferenceItems.messageFrequency`)}>
                          <RHFSelect label='Choose' name='messageFrequency'>
                            {(messageFrequency || [])?.map((option, index) => (
                              <MenuItem key={index} value={option?.value}>
                                {option?.label}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                        </Block>
                      </Grid>
                      <Grid item xs={12}>
                        {watch().messageFrequency?.toLowerCase() === "weekly" && (
                          <Block label={t(`settings.preferences.preferenceItems.weekDay`)}>
                            <RHFSelect label='Choose' name='weekDay'>
                              {(weekDays || [])?.map(day => (
                                <MenuItem key={day?.value} value={day?.value}>
                                  {day?.label}
                                </MenuItem>
                              ))}
                            </RHFSelect>
                          </Block>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <AppButton
                      label={t("changeCurrentProgramStatusDialog.cancel")}
                      color='secondary'
                      onClick={onClose}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {!showPreferencesButton.value ? (
                      <AppLoadingButton label={t("dialog.change")} isLoading={isLoading} />
                    ) : (
                      <AppButton
                        label={t("changeCurrentProgramStatusDialog.editPreferencesShort")}
                        onClick={handleEditPrfs}
                        fullWidth
                      />
                    )}
                  </Grid>
                </Grid>
              </FormProvider>
            </Box>
          </DialogContent>
        </AppLoadingAndErrorWrapper>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value && !success.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason == "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t("changeCurrentProgramStatusDialog.areUSure")}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth={true}
              label={t("no")}
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label={t("yes")}
              isLoading={isLoading}
              onClick={() => mutate(dataToSend)}
            />
          </>
        }
      />
      {/* Success */}
      <ConfirmDialog
        open={success.value && confirm.value}
        onClose={onClose}
        content={
          watch().status == "RESTART"
            ? `${t(
                "changeCurrentProgramStatusDialog.restartContent"
              )} ${dataToSend?.startDate?.toDateString()}.`
            : watch().status == "HOLD"
            ? t("changeCurrentProgramStatusDialog.holdContent")
            : t("changeCurrentProgramStatusDialog.unsubscribeContent")
        }
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
};

export default ChangeCurrentProgramStatusDialog;
