import Badge from "@mui/material/Badge";
import { alpha, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocales } from "src/locales";
import { paths } from "src/routes/paths";

export default function NotificationsView () {
  const settings = useSettingsContext();
  const showEditProfile = useBoolean();
  const { t } = useLocales();
  const { useGetListApi, usePutApi } = useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("all");
  const [allNotificationCount, setAllNotificationCount] = useState<number>(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState<number>(0);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);

  const TABS = [
    {
      value: "all",
      label: `${t("notificationsPage.tabs.all")}`,
      count: allNotificationCount,
    },
    {
      value: "unread",
      label: `${t("notificationsPage.tabs.unread")}`,
      count: unreadNotificationCount,
      read: false,
    },
  ];

  const {
    data: notifications,
    isLoading,
    isRefetching,
    error,
    refetch,
  } = useGetListApi<ResDataType["notifications"]>({
    url: API_URLS.NOTIFICATIONS,
    onSuccess: (data: ResDataType["notifications"]) => {
      setAllNotificationCount(data.items.length);
      setUnreadNotificationCount(data.items.filter(notification => !notification.read).length);
    },
  });

  const { mutate, isLoading: isMarking } = usePutApi({
    url: API_URLS.MARK_AS_READ,
    onSuccess: _ => {
      refetch();
    },
  });

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper isLoading={isLoading  || isRefetching} errorMessage={error}>
          <>
            <CustomBreadcrumbs
              links={[
                {
                  name: t("breadcrumbs.home"),
                  href: paths.dashboard.root,
                },
                {
                  name: t("breadcrumbs.notifications"),
                },
              ]}
            />
            <Stack
              direction='row'
              spacing={3}
              alignItems='flex-start'
              justifyContent='space-between'
            >
              <Tabs
                value={currentTabValue}
                onChange={handleChangeTab}
                sx={{
                  boxShadow: theme => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                  mb: 5,
                  "& button": {
                    marginLeft: "revert !important",
                  },
                }}
              >
                {TABS.map(tab => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={
                      <Stack direction='row' spacing={2} px={2} alignItems='center'>
                        <Typography>{tab.label}</Typography>
                        <Badge
                          badgeContent={tab.count ? tab.count : "0"}
                          color={tab.value === "all" ? "primary" : "secondary"}
                        />
                      </Stack>
                    }
                    sx={{
                      m: 0,
                    }}
                    onClick={e => handleChangeTab(e, tab.value)}
                  />
                ))}
              </Tabs>
              <AppLoadingButton
                size='small'
                label={t("notificationsPage.markAllAsRead")}
                fullWidth
                isLoading={isMarking}
                onClick={mutate}
                sx={{ width: "max-content" }}
              />
            </Stack>
            {notifications?.items
              .filter(notification => (currentTabValue !== "all" ? !notification.read : true))
              .map((notification, index) => (
                <Stack spacing='21px'>
                  {index ? <Divider /> : null}
                  <Typography mb='21px' fontWeight={!notification.read ? "bold" : ""}>
                    {notification.message}
                  </Typography>
                </Stack>
              ))}
          </>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
