import { ResDataType } from 'src/api/apiUrls';

export const NOTIFICATIONS_LIST = (userData: ResDataType['userProfile']) => [
  {
    name: 'newProgramNotification',
    value: userData?.newProgramNotification,
  },
  {
    name: 'finishProgramNotification',
    value: userData?.finishProgramNotification,
  },
  {
    name: 'receivedGiftNotification',
    value: userData?.receivedGiftNotification,
  },
  {
    name: 'winningBadgeNotification',
    value: userData?.winningBadgeNotification,
  },
  {
    name: 'deliveredGiftNotification',
    value: userData?.deliveredGiftNotification,
  },
];