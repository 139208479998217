import { useMemo } from "react";
// routes
import { paths } from "src/routes/paths";
// components
import SvgColor from "src/components/svg-color";
import Iconify from "src/components/iconify/iconify";
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: icon("ic_dashboard"),
  programs: icon("ic_program"),
  gifts: icon("ic_gifts"),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t, currentLang } = useLocales();

  const data = useMemo(
    () => [
      // ----------------------------------------------------------------------
      {
        subheader: "",
        items: [
          { title: t("navbar.home"), path: paths.dashboard.root, icon: ICONS.dashboard },
          // { title: 'two', path: paths.dashboard.two, icon: ICONS.ecommerce },
        ],
      },

      // ----------------------------------------------------------------------
      {
        subheader: "",
        items: [
          {
            title: t("navbar.programs.title"),
            path: paths.dashboard.programs.current,
            icon: ICONS.programs,
            children: [
              { title: t("navbar.programs.types.all"), path: paths.dashboard.programs.all },
              { title: t("navbar.programs.types.current"), path: paths.dashboard.programs.current },
              { title: t("navbar.programs.types.past"), path: paths.dashboard.programs.past },
            ],
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: t("navbar.gifts.title"),
            path: paths.dashboard.gifts.received,
            icon: ICONS.gifts,
            children: [
              { title: t("navbar.gifts.types.received"), path: paths.dashboard.gifts.received },
              { title: t("navbar.gifts.types.sent"), path: paths.dashboard.gifts.sent },
            ],
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: t("navbar.badges"),
            path: paths.dashboard.badges,
            icon: <Iconify icon="icon-park-twotone:five-star-badge" />,
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: t("navbar.settings"),
            path: paths.dashboard.settings,
            icon: <Iconify icon="fluent:settings-20-regular" width="24px" height="24px" />,
          },
        ],
      },
    ],
    [currentLang]
  );

  return data;
}
