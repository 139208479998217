import { translationJsonType } from "./type";

const englishJson: translationJsonType = {
  public: {
    dropDownPlaceHolder: "Select",
  },
  programButtons: {
    continueDate: "Set your continue date",
    restartDate: "Set your restart date",
  },
  table: {
    rowsPerPageLabel: "Rows per page: ",
  },
  emailOrPhoneInvalid: "Email / Phone is invalid",
  editPersonalInfoDialog: {
    header: "Edit personal preferences",
    firstName: {
      label: "First name",
      placeholder: "Enter first name",
    },
    lastName: {
      label: "Last name",
      placeholder: "Enter last name",
    },
    email: {
      label: "Email address",
      placeholder: "Enter email address",
    },
    save: "Save",
    cancel: "Cancel",
    formErrorMessages: {
      onlyLetters: "This field must contain only letters",
    },
  },
  cannotRestartProgramDialog: {
    header: "Unfortunately, You can't restart the program now.",
    paragraph:
      "It appears that you have subscribed to another program at the moment. To restart this program, please finish your current program first and then try restarting it.",
    button: "Back to current program",
  },
  notificationsPage: {
    markAllAsRead: "Mark all as read",
    tabs: {
      all: "All",
      unread: "Unread",
    },
  },
  navbar: {
    home: "Home",
    programs: {
      title: "Programs",
      types: {
        current: "Current program",
        past: "Past programs",
        all: "All programs",
      },
    },
    gifts: {
      title: "Gifts",
      types: {
        received: "Received",
        sent: "Sent",
      },
    },
    settings: "Settings",
    badges: "Badges",
    logout: "Logout",
  },
  header: {
    notificationsPopover: {
      title: "Notifications",
      seeAll: "See All",
    },
    languagesPopover: {
      English: "English",
      Spanish: "Spanish",
      Arabic: "Arabic",
    },
    settingsDrawer: {
      title: "Settings",
      mode: "Mode",
      contrast: "Contrast",
      direction: "Direction",
      layout: "Layout",
      stretch: "Stretch",
      presets: "Presets",
      fullScreen: "Fullscreen",
      exitFullScreen: "Exit Fullscreen",
    },
    profilePopover: {
      personalInfo: "Personal information",
      logout: "Logout",
    },
  },
  breadcrumbs: {
    home: "Home",
    settings: "Settings",
    gifts: "Gifts",
    receivedGifts: "Received Gifts",
    sentGifts: "Sent Gifts",
    profile: "Profile",
    programs: "Programs",
    preferences: "Preferences",
    programStatus: "Program Status",
    notifications: "Notifications",
    password: "Password",
    personalInfo: "Personal information",
    currentProgram: "Current Program",
    pastPrograms: "Past Programs",
    allPrograms: "All Programs",
  },
  dialog: {
    cancel: "Cancel",
    change: "Change",
    congratulations: "Congratulations! You have successfully changed your preferences",
    areYouSure: "Are you sure you want to Change your preferences?",
  },
  logoutDialog: {
    areYouSure: "Are you sure you want to logout from Days of Iman?",
  },
  login: {
    title: "Days of Iman",
    description:
      "Days of Iman focuses on providing a faith experience for new converts of Islam or Existed Muslims, so that it builds the basics of its faith, brick by brick until the bonds of Islam are anchored to it through the following",
    loginPhone: "Login by phone",
    loginEmail: "Login by email",
    form: {
      phoneNumber: {
        label: "Phone number",
        placeHolder: "Enter your phone number",
      },
      email: {
        label: "Email",
        placeHolder: "Enter your email",
      },
      password: {
        label: "Password",
        placeHolder: "Enter your password",
      },
      forgetPassword: "Forget password?",
      rememberMe: "Remember me",
      loginButton: "Login",
      "didn'tSubscribe": "Don’t you have an account?",
      subscribeNow: "Create Account",
    },
  },
  otp: {
    confirmYourInfo: "Confirm your information",
    chooseToVerify:
      "Please choose email or phone number to verify your account to set your password and continue subscription",
    chooseEmailOrPhone: "Choose email or phone number",
    sendMailTo: "Send Code To Email",
    sendSMSTo: "Send Code To SMS",
    confirm: "Confirm",
    "didn'tSubscribe": "You didn't subscribe before?",
    subscribeNow: "Subscribe now",
    enterOtp: {
      enterCode: "Enter the code",
      messageSent: "A message was sent to your email",
      enterToVerifyEmail: "enter the code to verify your account to recover your password",
      enterToVerifyPhone: "enter the code to verify your phone number",
      didntReceive: "You didn’t receive any code yet?",
      resend: "Resend it",
      verify: "Verify",
    },
  },
  resetPassword: {
    header: "Reset your password",
    enterEmailOrPhone:
      "Enter your email address or your phone number and we will send you an OTP code to reset your password.",
    emailOrPhone: {
      label: "Email or phone number",
      placeholder: "Enter your email or phone number",
    },
    didntSubcribe: "You didn't subscribe before?",
    subscribeNow: "Subscribe now",
    confirm: "Confirm",
    formErrorMessages: {
      passwordLength: "Password must be at least 8 characters long",
      mustContainLowerCase: "Password must contain at least one lowercase letter",
      mustContainUpperCase: "Password must contain at least one uppercase letter",
      mustContainNumber: "Password must contain at least one number",
      mustContainSpecialChar: "Password must contain at least one special character",
      mustMatchPassword: "Must match the new password",
    },
  },
  subscribe: {
    header:
      "Subscribe to the Days of Iman Programs and deepen your involvement in Islamic principles.",
    description1:
      "Days of Iman focuses on providing a faith experience for new converts to the Islamic religion so that it builds the basics of its faith, brick by brick until the bonds of Islam are anchored to it. Subscribe now to our mailing list and start to receive messages. We will not sell or distribute your data to any third party. View our",
    description2: "for more details",
    privacyPolicy: "Privacy policy",
    form: {
      dropdown: "Choose",
      firstName: {
        label: "First Name",
        placeholder: "Enter your first name",
      },
      lastName: {
        label: "Last Name",
        placeholder: "Enter your last name",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email",
      },
      mobilePhone: {
        label: "Mobile phone",
        placeholder: "Enter your mobile phone",
      },
      chooseTheProgram: "Choose the program",
      preferredSendingChannel: "Preferred sending channel",
      customize: "Customize your messages experience",
      tellUsAboutU: "Firstly, tell us more about you",
      birthday: "Birthday",
      gender: "Your gender",
      islamicStatus: "Your islamic status",
      specialization: {
        label: "Your specialization",
        placeholder: "Enter your specialization",
      },
      tellUsPreference: "Second, Tell us how do you prefer to receive your message",
      topicLength: "Your Topic length",
      preferredLanguage: "Your preferred language",
      preferredTime: "Your preferred time",
      messageFrequency: "Your Message frequency",
      frequencyHelperText: "For Optimum Benefits Set Your Schedule Daily",
      description: "Describe Why You Subscribe In Our Newsletter",
      subscribeAndLogin: "Subscribe & Login",
      subscripitonBefore: "Subscribed to a program before and don’t have an account?",
      continueSubscription: "Create an account here",
      weekDay: "Week day",
      sendingChannelHelper: "Starting bot is required",
    },
    formErrorMessages: {
      oneChannel: "You must select one channel at least",
    },
  },
  setPassword: {
    setPassword: "Set password",
    pleaseSetPassword: "Please set password for your account",
    password: {
      label: "Password",
      placeHolder: "Create your password",
    },
    confirmPassword: {
      label: "Confirm password",
      placeHolder: "Confirm your password",
    },
    passwordRules: {
      mustContains: "Password must contains:",
      eightChars: "Contains at least 8 characters",
      oneCapital: "Contains at least one capital letter",
      oneSmall: "Contains at least one small letter",
      oneNumber: "Contains at least one number",
      special: "Contains special characters !@#$%^&*",
    },
  },
  continueSubscription: {
    header: "Confirm your information",
    description:
      "Please enter your phone number or your email to verify your account and continue subscription",
    emailOrPhone: {
      label: "Email or phone number",
      placeholder: "Enter your email or phone number",
    },
    confirm: "Confirm",
    "didn'tSubscribe": "You have an account?",
    subscribeNow: "Login",
  },
  userProfile: {
    firstName: "First name",
    lastName: "Last name",
    phoneNumber: "Phone number",
    emailAddress: "Email address",
    editProfile: "Edit Profile",
  },
  home: {
    latestGifts: "Your latest gifts",
    giftsDetails: "See gifts details",
    changeProgramStatus: "Change Program Status",
    currentProgram: "Current Program",
    currentProgramDetails: "See program details",
    progress: "Progress",
    currentDescription:
      "Your journey at {{programName}} program you're almost done. So we recommended the following program to subscribe and start new journey's faith with it.",
    endDate: "End date",
    messageMedium: "Message medium",
    subscribe: "Subscribe",
    discoverProgram: "Discover Program",
  },
  gifts: {
    table: {
      toolbar: {
        searchPlaceHolder: "Search",
        filters: "Filters",
      },
      filtersDrawer: {
        program: "Program",
        messageType: "Message Type",
        messageStatus: "Message Status",
        applyFilters: "Apply Filters",
      },
      fields: {
        gift: "Gift",
        sender: "Sender",
        recipient: "Recipient",
        program: "Program",
        message: "Message",
        type: "Type",
        status: "Status",
        actions: "Actions",
      },
      actions: {
        details: "Details",
        send: "Send",
      },
    },
    noGifts:
      "You have not received or sent any gifts yet. Spread Islamic manners within your circles.",
    sendGiftButton: "Send Gift",
    giftPagination: {
      recipientForm: "Recipient Form",
      yourInformation: "Your Information",
      back: "Back",
      next: "Next",
      submit: "Submit",
      cardDesign: "1- Select card design",
      cardColor: "2- Select your card color",
      changeProgram: "Change Program",
      customizeYourGift: "Customize your gift",
      sendingType: "Sending Type",
    },
    form: {
      firstName: {
        label: "First Name",
        placeholder: "Enter recipient first name",
      },
      lastName: {
        label: "Last Name",
        placeholder: "Enter recipient last name",
      },
      email: {
        label: "Email",
        placeholder: "Enter recipient email",
      },
      preferredSendingChannel: "Preferred sending channel",
      preferredSendingDate: "Preferred sending date",
      customizeMessage: "Customize recipient message experience",
      tellUsAboutU: "Firstly, tell us more about you",
      dateOfBirth: "Date of birth",
      gender: "Gender",
      islamicStatus: "Islmaic status",
      specialization: "Recipient specialized",
      tellUsPreference: "Second, Tell us how do you prefer to receive your message",
      messageFrequency: "Recipient message frequency",
      topicLength: "Program topic length",
      preferredLanguage: "Program preferred language",
      preferredTime: "Recipient preferred time",
      giftMessage: "Gift Message",
      messageFrequencyHelperText: "For Optimum Benefits Set Recipient Schedule Daily",
      weekDay: "Week day",
      makeSure: "Make sure all required fields are filled",
    },
    program: "Program",
    filters: "Filters",
    apply: "Apply filters",
    reset: "Reset all filters",
    yourGift: "Your Gift",
    recipientInformation: "Recipient information",
    senderInformation: "Sender Information",
    giftMessage: "Gift Message",
    formErrorMessages: {
      onlyLetters: "This field must contain only letters",
      selectOneChannel: "Select one channel at least",
      validEmail: "Must contain a valid email address",
    },
  },
  badges: {
    done: "Well done. You've read all messages",
    inProgress: "Complete reading message to win the badge",
    noBadges:
      "You didn't win any badges until now. Subscribe and begin learning Islamic principles, earning badges for each accomplishment.",
    seePrograms: "See all programs",
    win: "You win the {{programName}} badge",
    notWin: "Thumbs up! You're nearing the end of the program to win a badge {{programName}} ",
  },
  settings: {
    tabs: {
      preferences: "Preferences",
      programStatus: "Program Status",
      notifications: "Notifications",
      password: "Password",
    },
    preferences: {
      editPreferences: "Edit preferences",
      personalPreferences: "Personal Preferences",
      messagePreferences: "Message preferences",
      preferenceItems: {
        dateOfBirth: "Date of birth",
        gender: "Gender",
        islamicStatus: "Islmaic status",
        specialized: "Specialized",
        topicLength: "Topic length",
        programLanaguage: "Program language",
        messageMedium: "Message medium",
        messageTime: "Message time",
        messageFrequency: "Message frequency",
        weekDay: "Week day",
      },
    },
    programStatus: {
      change: "Change",
      inProgress: "In progress",
      status: {
        notParticipated: "You're not participated in any programs yet",
        restart: "Your program will restart on",
        hold: "Your program is holded now and will restart at",
        completed: "Congratulations you have completed the program in",
        inProgress: "Your program is in progress",
      },
    },
    notifications: {
      saveChanges: "Save Changes",
      newProgramNotification: "Start new program",
      finishProgramNotification: "Finish the program",
      receivedGiftNotification: "Received new gift",
      winningBadgeNotification: "Winning badge",
      deliveredGiftNotification: "Delivered the gift",
    },
    password: {
      currentPassword: {
        title: "Current Password",
        placeHolder: "Enter the current password",
      },
      newPassword: {
        title: "New Password",
        placeHolder: "Enter the new password",
      },
      confirmPassword: {
        title: "Confirm Password",
        placeHolder: "Confirm the new password",
      },
      cancel: "Cancel",
      change: "Save & Change",
      formErrorMessages: {
        passwordLength: "Password must be at least 8 characters long",
        mustContainLowerCase: "Password must contain at least one lowercase letter",
        mustContainUpperCase: "Password must contain at least one uppercase letter",
        mustContainNumber: "Password must contain at least one number",
        mustContainSpecialChar: "Password must contain at least one special character",
        mustMatchPassword: "Must match the new password",
      },
    },
  },
  pastPrograms: {
    whatUSubcribeBefore: "What you subscribe before",
    restartProgram: "Restart program",
    subscribeAgain: "Subscribe again",
    continueProgram: "Continue program",
    noPrograms: "No programs to show here",
    areUSure: "Are you sure you want to Change program status?",
    formErrorMessages: {
      restartDate: "Restart date is required",
    },
    editPreferences: "Edit preferences",
    cancel: "cancel",
  },
  changeCurrentProgramStatusDialog: {
    changeStatus: "Change program status to",
    editPreferences: "Edit current program preferences",
    editPreferencesShort: "Edit preferences",
    subscribeDate: "Set your subscribe date",
    programStatus: "Program Status *",
    restart: "Restart",
    holdLabel: "Hold",
    unsubscribeLabel: "Unsubscribe",
    restartDate: "Restart date",
    cancel: "Cancel",
    save: "Save",
    areUSure: "Are you sure you want to Change program status?",
    setYourRestartDate: "Set your restart date",
    hold: {
      restartInDate: "Restart in specific date",
    },
    unsubscribe: {
      reason: "Reason",
      notInterested: "I am not intersted anymore",
      needsImprovement: "The content needs improvement",
      other: "other",
    },
    restartContent: "Your program will restart at ",
    holdContent: "Your program hold successfully",
    unsubscribeContent: "You have successfully unsubscribed from the program.",
    programPreferences: "Change program preferences",
    formErrorMessages: {
      restartDate: "Restart date is required",
    },
  },
  changeCurrentProgramPreferencesDialog: {
    editCurrentPrgPrf: "Edit current program preferences",
    areUSure: "Are you sure you want to Change program preferences?",
    saveChanges: "Save Changes",
    cancel: "Cancel",
    formErrorMessages: {
      oneChannel: "You must select one channel at least",
    },
  },
  subscribeToNewProgramDialog: {
    header: "Subscribe to program",
    date: "Start date",
    messageMedium: "Message medium",
    subscribe: "Subscribe",
    cancel: "Cancel",
    areUSure: "Are you sure you want to subscribe to this program?",
    formErrorMessages: {
      oneChannel: "You must select one channel at least",
    },
  },
  changeGiftProgramDialog: {
    changeProgramHeader: "Do you want to change program?",
    chooseProgramHeader: "Choose a program",
    cancel: "Cancel",
    change: "Change",
    add: "Add",
  },
  giftingPopup: {
    title: "Congratulations! Your gift has been customized well and will be sent like you prefer.",
  },
  currentProgram: {
    description:
      "Your journey at {{programName}} program you're almost started.",
    startDate: "Start date",
    endDate: "End date",
    lastMessageOpen: "Last message open at",
    frequency: "Frequency",
    time: "Time",
    messageMedium: "Message medium",
    editPreferences: "Edit preferences",
    messagesLeft: "Messages left",
    congratulations: "Congratulations",
    latestMessages: "Latest messages",
    noPrograms: "No programs to show here",
  },
  allPrograms: {
    programName: "Program Name",
    programImage: "Program Image",
  },
  statistics: {
    recivedGiftCount: "Total Gifts",
    totalMessagesCount: "Total Messages",
    userWhatsappMessagesCount: "WhatsApp Messages",
    userEmailMessagesCount: "Email Messages",
    userSmsMessagesCount: "SMS Messages",
    userProgramsCount: "Total Programs",
    "Gifts count": "Total Gifts",
    "Whatsapp message gifts count": "WhatsApp Messages",
    "Email message gifts count": "Email Messages",
    "Sms message gifts count": "SMS Messages",
  },
  telegramBot: {
    title: "Telegram Bot",
    description:
      "Use Telegram bot, receive messages,  summarizing the main ideas that we present it in emails, in order to be light and easy to get the core meaning of topic.",
    subtitle:
      "To begin receiving Telegram messages, you'll need to start using our bot. Don't worry; it's quick.",
    subtitle1: "1. Click on the Connect Telegram button.",
    subtitle2: "2. Start using Days of Iman Telegram bot.",
    button: "Connect Telegram",
  },
  yes: "Yes",
  no: "No",
  completedSuccessfully: "Completed Successfully",
  date: "Date",
  messageChannel: "Message Channel",
};

export default englishJson;
