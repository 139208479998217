import { useLocales } from "src/locales";
import * as Yup from "yup";

const validateEmail = (email: string | undefined) => {
  return Yup.string().email().isValidSync(email);
};

const validatePhone: any = (phone: number | undefined) => {
  return Yup.number()
    .integer()
    .positive()
    .test((phone: any) => {
      return phone && phone.toString().length >= 8 && phone.toString().length <= 14 ? true : false;
    })
    .isValidSync(phone);
};

const useSchemas = () => {
  const { t } = useLocales();
  // Gift form schema
  const GiftFormSchema = Yup.object().shape({
    recipientFirstName: Yup.string()
      .required(" ")
      .min(2)
      .max(50)
      .matches(
        /^[^~!@#$%^&*()_+|}{“:?><[\]\\;',./-=\d]+$/,
        t("gifts.formErrorMessages.onlyLetters")
      ),
    recipientLastName: Yup.string()
      .required(" ")
      .min(2)
      .max(50)
      .matches(
        /^[^~!@#$%^&*()_+|}{“:?><[\]\\;',./-=\d]+$/,
        t("gifts.formErrorMessages.onlyLetters")
      ),
    recipientEmail: Yup.string().required(" ").email(t("gifts.formErrorMessages.validEmail")),
    mobilePhone: Yup.string().required(" "),
    giftProgramId: Yup.number().required(" "),
    programMessageMedium: Yup.array()
      .min(1, t("gifts.formErrorMessages.selectOneChannel"))
      .required(" ")
      .of(Yup.string().required(" ")),
    recipientDateOfBirth: Yup.string().required(" "),
    recipientGender: Yup.string().required(" "),
    recipientIslamicStatus: Yup.string().required(" "),
    recipientSpecialized: Yup.string().required(" "),
    giftMessageMedium: Yup.string().required(" "),
    programTopicLength: Yup.string().required(" "),
    programLanaguage: Yup.string().required(" "),
    recipientMessageTime: Yup.string().required(" "),
    recipientMessageFrequency: Yup.string().required(" "),
    recipientWeekDay: Yup.string(),
    giftMessage: Yup.string().required(" "),
    giftSendingDate: Yup.string().required(" "),
    giftSendingType: Yup.string().required(" "),
    cardImage: Yup.string().required(" "),
  });
  // Change password form schema
  const ChangePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required(" "),
    password: Yup.string()
      .required(" ")
      .min(8, t("settings.password.formErrorMessages.passwordLength"))
      .matches(/[a-z]/, t("settings.password.formErrorMessages.mustContainLowerCase"))
      .matches(/[A-Z]/, t("settings.password.formErrorMessages.mustContainUpperCase"))
      .matches(/[0-9]/, t("settings.password.formErrorMessages.mustContainNumber"))
      .matches(/[!@#$%^&*]/, t("settings.password.formErrorMessages.mustContainSpecialChar")),
    confirmPassword: Yup.string()
      .required(" ")
      .oneOf([Yup.ref("password")], t("settings.password.formErrorMessages.mustMatchPassword")),
  });
  // Subscribe to new program schema (New user)
  const SubscribeSchema = Yup.object().shape({
    firstName: Yup.string().required(" "),
    lastName: Yup.string().required(" "),
    email: Yup.string().email().required(" "),
    mobilePhone: Yup.string().required(" "),
    programId: Yup.number().required(" "),
    messageMedium: Yup.array()
      .of(Yup.string().required(" "))
      .required(t("subscribe.formErrorMessages.oneChannel"))
      .min(1, t("subscribe.formErrorMessages.oneChannel")),
    dateOfBirth: Yup.string().required(" "),
    gender: Yup.string().required(" "),
    islamicStatus: Yup.string().required(" "),
    specialized: Yup.string().required(" "),
    topicLength: Yup.string().required(" "),
    programLanaguage: Yup.string().required(" "),
    messageTime: Yup.string().required(" "),
    messageFrequency: Yup.string().required(" "),
    description: Yup.string(),
    weekDay: Yup.string(),
  });
  // Change current program status
  const ChangeStatusSchema = (required: boolean) =>
    Yup.object().shape({
      status: Yup.string().required(" "),
      startDate: Yup.date()
        .required(" ")
        .test("startDate", " ", function (value) {
          const { status, hasRestartDate } = this.parent;
          if (status === "RESTART" || (status === "HOLD" && hasRestartDate)) {
            return Yup.date().required(" ").isValidSync(value);
          }
          return true;
        }),
      language: required ? Yup.string().required(" ") : Yup.string(),
      hasRestartDate: Yup.boolean(),
      topicLength: required ? Yup.string().required(" ") : Yup.string(),
      messageFrequency: required ? Yup.string().required(" ") : Yup.string(),
      weekDay: Yup.string(),
      messageTime: required ? Yup.date().required(" ") : Yup.date(),
      messageMedium: required
        ? Yup.array().of(Yup.string().required(" ")).required(" ")
        : Yup.array().of(Yup.string().required(" ")),
    });
  // Login
  const LoginSchema = Yup.object().shape({
    loginId: Yup.string().required(" "),
    password: Yup.string().required(" "),
  });
  // Reset password
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(" ")
      .min(8, t("resetPassword.formErrorMessages.passwordLength"))
      .matches(/[a-z]/, t("resetPassword.formErrorMessages.mustContainLowerCase"))
      .matches(/[A-Z]/, t("resetPassword.formErrorMessages.mustContainUpperCase"))
      .matches(/[0-9]/, t("resetPassword.formErrorMessages.mustContainNumber"))
      .matches(/[!@#$%^&*]/, t("resetPassword.formErrorMessages.mustContainSpecailChar")),
    confirmPassword: Yup.string()
      .required(" ")
      .oneOf([Yup.ref("password")], t("resetPassword.formErrorMessages.mustMatchPassword")),
  });
  // Change current program status
  const ChangeCurrentProgramStatusSchema = (required: boolean) =>
    Yup.object().shape({
      status: Yup.string().required(" "),
      startDate: Yup.date().test("startDate", " ", function (value) {
        const { status, hasRestartDate } = this.parent;
        if (status === "RESTART" || (status === "HOLD" && hasRestartDate)) {
          return Yup.date().required(" ").isValidSync(value);
        }
        return true;
      }),
      hasRestartDate: Yup.boolean(),
      reason: Yup.string(),
      programLanaguage: required ? Yup.string().required(" ") : Yup.string(),
      topicLength: required ? Yup.string().required(" ") : Yup.string(),
      messageFrequency: required ? Yup.string().required(" ") : Yup.string(),
      weekDay: Yup.string(),
      messageTime: required ? Yup.date().required(" ") : Yup.date(),
      messageMedium: required
        ? Yup.array().of(Yup.string().required(" ")).required(" ")
        : Yup.array().of(Yup.string().required(" ")),
    });
  // Change curren program preferences
  const ChangeCurrentProgramPreferencesSchema = Yup.object().shape({
    messageMedium: Yup.array()
      .min(1, t("changeCurrentProgramPreferencesDialog.formErrorMessages.oneChannel"))
      .required(" ")
      .of(Yup.string().required(" ")),
    programLanaguage: Yup.string().required(" "),
    topicLength: Yup.string().required(" "),
    messageFrequency: Yup.string().required(" "),
    weekDay: Yup.string(),
    messageTime: Yup.date().required(" "),
  });
  // Subscribe to new program
  const SubscribeToNewProgramSchema = Yup.object().shape({
    messageMedium: Yup.array()
      .min(1, t("subscribeToNewProgramDialog.formErrorMessages.oneChannel"))
      .required(" ")
      .of(Yup.string().required(" ")),
    programId: Yup.number().required(" "),
    startDate: Yup.date().required(" "),
    programLanaguage: Yup.string().required(" "),
    topicLength: Yup.string().required(" "),
    messageFrequency: Yup.string().required(" "),
    weekDay: Yup.string(),
    messageTime: Yup.date().required(" "),
  });
  // Change personal info
  const ChangePersonalInfoSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(" ")
      .min(2)
      .max(50)
      .matches(
        /^[^~!@#$%^&*()_+|}{“:?><[\]\\;',./-=\d]+$/,
        t("editPersonalInfoDialog.formErrorMessages.onlyLetters")
      ),
    lastName: Yup.string()
      .required(" ")
      .min(2)
      .max(50)
      .matches(
        /^[^~!@#$%^&*()_+|}{“:?><[\]\\;',./-=\d]+$/,
        t("editPersonalInfoDialog.formErrorMessages.onlyLetters")
      ),
    mobilePhone: Yup.string().required(" "),
  });
  // Subscribe (all programs)
  const SubscribeAllProgramSchema = Yup.object().shape({
    startDate: Yup.date().required(" "),
  });
  // Continue subscription
  const ContinueSubscriptionSchema = Yup.object().shape({
    subscriptionId: Yup.string()
      .required(" ")
      .test("subscriptionId", t("emailOrPhoneInvalid"), (value) => {
        return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
      }),
  });
  // Return schemas
  return {
    GiftFormSchema,
    ChangePasswordSchema,
    SubscribeSchema,
    ChangeStatusSchema,
    LoginSchema,
    ResetPasswordSchema,
    ChangeCurrentProgramStatusSchema,
    ChangeCurrentProgramPreferencesSchema,
    SubscribeToNewProgramSchema,
    ChangePersonalInfoSchema,
    SubscribeAllProgramSchema,
    ContinueSubscriptionSchema,
  };
};

export default useSchemas;
