import { Icon } from "@iconify/react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { WEBISTE_URL } from "src/config-global";
import { useLocales } from "src/locales";

const NoBadges = () => {
  const { t } = useLocales();
  return (
    <Grid
      item
      container
      gap={3}
      direction='column'
      justifyContent='center'
      alignItems='center'
      mt={10}
    >
      <Icon icon='charm:image' fontSize='80px' />
      <Typography fontSize={32} fontWeight={600} width={{ md: 564 }} align='center'>
        {t("badges.noBadges")}
      </Typography>
      {/* // TODO: Edit this link */}
      <Link to={`/dashboard/programs/all`} style={{ textDecoration: "none" }}>
        <Button variant='contained' size='large'>
          {t("badges.seePrograms")}
        </Button>
      </Link>
    </Grid>
  );
};

export default NoBadges;
