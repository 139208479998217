import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname, useRouter } from 'src/routes/hook';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';
import { Button } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { usePopover } from 'src/components/custom-popover';
import { useBoolean } from 'src/hooks/use-boolean';
import LogoutPopup from './LogoutPopup';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import DarkModeLogo from 'src/components/dark-mode-logo/dark-mode-logo';
//

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useMockedUser();

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const smDown = useResponsive('down', 'sm');

  const navData = useNavData();

  const dialog = useBoolean();

  const { t } = useLocales();

  const settings = useSettingsContext();

  const isLight = settings.themeMode === 'light';

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        p: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {isLight ? (
        <Logo
          sx={{ mb: 1.5, ml: 4, display: 'flex', justifyContent: 'center', alignContent: 'center' }}
        />
      ) : (
        <DarkModeLogo
          sx={{ mb: 1.5, ml: 4, display: 'flex', justifyContent: 'center', alignContent: 'center' }}
        />
      )}

      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavUpgrade /> */}
      {!smDown ? (
        <Button onClick={() => dialog.onTrue()} variant="outlined">
          {t('navbar.logout')}
        </Button>
      ) : (
        <Button fullWidth onClick={() => dialog.onTrue()} variant="outlined">
          {t('navbar.logout')}
        </Button>
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
      <LogoutPopup dialog={dialog} />
    </Box>
  );
}
