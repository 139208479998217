import { translationJsonType } from "./type";

const spanishJson: translationJsonType = {
  public: {
    dropDownPlaceHolder: "Seleccionar",
  },
  programButtons: {
    continueDate: "establece tu fecha de continuación",
    restartDate: "establece tu fecha de reinicio",
  },
  table: {
    rowsPerPageLabel: "Filas por página: ",
  },
  emailOrPhoneInvalid: "El correo electrónico / teléfono no es válido",
  editPersonalInfoDialog: {
    header: "Editar preferencias personales",
    firstName: {
      label: "Nombre de pila",
      placeholder: "Introduce el nombre",
    },
    lastName: {
      label: "Apellido",
      placeholder: "Introduzca el apellido",
    },
    email: {
      label: "Dirección de correo electrónico",
      placeholder: "Introducir la dirección de correo electrónico",
    },
    save: "Ahorrar",
    cancel: "Cancelar",
    formErrorMessages: {
      onlyLetters: "Este campo debe contener solo letras.",
    },
  },
  cannotRestartProgramDialog: {
    header: "Desafortunadamente, no puedes reiniciar el programa ahora.",
    paragraph:
      "Parece que te has suscrito a otro programa en este momento. Para reiniciar este programa, primero finalice el programa actual y luego intente reiniciarlo.",
    button: "Volver al programa actual",
  },
  notificationsPage: {
    markAllAsRead: "Marcar todo como leido",
    tabs: {
      all: "Todo",
      unread: "No leído",
    },
  },
  navbar: {
    home: "Hogar",
    programs: {
      title: "Programas",
      types: {
        current: "Programa actual",
        past: "Programas pasados",
        all: "Todos los programas",
      },
    },
    gifts: {
      title: "Regalos",
      types: {
        received: "Recibió",
        sent: "Enviado",
      },
    },
    settings: "Ajustes",
    badges: "Insignias",
    logout: "Cerrar sesión",
  },
  header: {
    notificationsPopover: {
      title: "Notificaciones",
      seeAll: "Ver todo",
    },
    languagesPopover: {
      English: "Inglés",
      Spanish: "Español",
      Arabic: "Arábica",
    },
    settingsDrawer: {
      title: "Ajustes",
      mode: "Modo",
      contrast: "Contraste",
      direction: "Dirección",
      layout: "Disposición",
      stretch: "Estirar",
      presets: "Preajustes",
      fullScreen: "Pantalla completa",
      exitFullScreen: "Salir de pantalla completa",
    },
    profilePopover: {
      personalInfo: "Informacion personal",
      logout: "Cerrar sesión",
    },
  },
  breadcrumbs: {
    home: "Hogar",
    settings: "Ajustes",
    gifts: "Regalos",
    receivedGifts: "Regalos recibidos",
    sentGifts: "Regalos enviados",
    profile: "Perfil",
    programs: "Programas",
    preferences: "Preferencias",
    programStatus: "Estado del programa",
    notifications: "Notificaciones",
    password: "Contraseña",
    personalInfo: "Informacion personal",
    currentProgram: "Programa actual",
    pastPrograms: "Programas pasados",
    allPrograms: "Todos los programas",
  },
  dialog: {
    cancel: "Cancelar",
    change: "Cambiar",
    congratulations: "¡Felicidades! ",
    areYouSure: "¿Está seguro de que desea cambiar sus preferencias?",
  },
  logoutDialog: {
    areYouSure: "¿Estás seguro de que deseas cerrar sesión en Days of Iman?",
  },
  login: {
    title: "Días del Imán",
    description:
      "Days of Iman se centra en brindar una experiencia de fe a los nuevos conversos del Islam o a los musulmanes existentes, de modo que construyan los fundamentos de su fe, ladrillo a ladrillo hasta que los lazos del Islam queden anclados en él a través de lo siguiente.",
    loginPhone: "Iniciar sesión por teléfono",
    loginEmail: "Iniciar sesión por correo electrónico",
    form: {
      phoneNumber: {
        label: "Número de teléfono",
        placeHolder: "Ingrese su número telefónico",
      },
      email: {
        label: "Correo electrónico",
        placeHolder: "Introduce tu correo electrónico",
      },
      password: {
        label: "Contraseña",
        placeHolder: "Ingresa tu contraseña",
      },
      forgetPassword: "¿Contraseña olvidada?",
      rememberMe: "Acuérdate de mí",
      loginButton: "Acceso",
      "didn'tSubscribe": "¿No tienes una cuenta?",
      subscribeNow: "Crear una cuenta",
    },
  },
  otp: {
    confirmYourInfo: "Confirma tu información",
    chooseToVerify:
      "Elija el correo electrónico o el número de teléfono para verificar su cuenta, establecer su contraseña y continuar con la suscripción.",
    chooseEmailOrPhone: "Elija correo electrónico o número de teléfono",
    sendMailTo: "Enviar código al correo electrónico",
    sendSMSTo: "Enviar código a SMS",
    confirm: "Confirmar",
    "didn'tSubscribe": "¿No te suscribiste antes?",
    subscribeNow: "Suscríbase ahora",
    enterOtp: {
      enterCode: "Ingrese el código",
      messageSent: "Se envió un mensaje a su correo electrónico.",
      enterToVerifyEmail: "ingresa el código para verificar tu cuenta y recuperar tu contraseña",
      enterToVerifyPhone: "ingresa el código para verificar tu número de teléfono",
      didntReceive: "¿Aún no has recibido ningún código?",
      resend: "Reenvialo",
      verify: "Verificar",
    },
  },
  resetPassword: {
    header: "Restablecer su contraseña",
    enterEmailOrPhone:
      "Ingrese su dirección de correo electrónico o su número de teléfono y le enviaremos un código OTP para restablecer su contraseña.",
    emailOrPhone: {
      label: "Correo electrónico o número de teléfono",
      placeholder: "Introduce tu correo electrónico o número de teléfono",
    },
    didntSubcribe: "¿No te suscribiste antes?",
    subscribeNow: "Suscríbase ahora",
    confirm: "Confirmar",
    formErrorMessages: {
      passwordLength: "La contraseña debe tener al menos 8 caracteres",
      mustContainLowerCase: "La contraseña debe contener al menos una letra minúscula",
      mustContainUpperCase: "La contraseña debe contener al menos una letra mayúscula",
      mustContainNumber: "La contraseña debe contener al menos un número",
      mustContainSpecialChar: "La contraseña debe contener al menos un carácter especial",
      mustMatchPassword: "Debe coincidir con la nueva contraseña",
    },
  },
  subscribe: {
    header:
      "Suscríbase a los programas Days of Iman y profundice su participación en los principios islámicos.",
    description1:
      "Days of Iman se centra en brindar una experiencia de fe a los nuevos conversos a la religión islámica para que construyan los fundamentos de su fe, ladrillo a ladrillo hasta que los lazos del Islam queden anclados en ella. ",
    description2: "para más detalles",
    privacyPolicy: "Política de privacidad",
    form: {
      dropdown: "Elegir",
      firstName: {
        label: "Nombre de pila",
        placeholder: "Ponga su primer nombre",
      },
      lastName: {
        label: "Apellido",
        placeholder: "Introduce tu apellido",
      },
      email: {
        label: "Correo electrónico",
        placeholder: "Introduce tu correo electrónico",
      },
      mobilePhone: {
        label: "Teléfono móvil",
        placeholder: "Introduce tu teléfono móvil",
      },
      chooseTheProgram: "Elige el programa",
      preferredSendingChannel: "Canal de envío preferido",
      customize: "Personaliza tu experiencia de mensajes",
      tellUsAboutU: "Primero, cuéntanos más sobre ti.",
      birthday: "Cumpleaños",
      gender: "Tu género",
      islamicStatus: "Tu estatus islámico",
      specialization: {
        label: "Tu especialización",
        placeholder: "Introduce tu especialización",
      },
      tellUsPreference: "Segundo, cuéntanos cómo prefieres recibir tu mensaje.",
      topicLength: "Longitud de tu tema",
      preferredLanguage: "Tu idioma preferido",
      preferredTime: "Tu horario preferido",
      messageFrequency: "La frecuencia de tus mensajes",
      frequencyHelperText: "Para obtener beneficios óptimos, establezca su horario diariamente",
      description: "Describa por qué se suscribe a nuestro boletín",
      subscribeAndLogin: "Suscribir",
      subscripitonBefore: "¿Te suscribiste a un programa antes y no tienes una cuenta?",
      continueSubscription: "Crea una cuenta aquí",
      weekDay: "Día laborable",
      sendingChannelHelper: "Se requiere iniciar el bot",
    },
    formErrorMessages: {
      oneChannel: "debes seleccionar un canal al menos",
    },
  },
  setPassword: {
    setPassword: "Configurar la clave",
    pleaseSetPassword: "Por favor establezca la contraseña para su cuenta",
    password: {
      label: "Contraseña",
      placeHolder: "Crea tu contraseña",
    },
    confirmPassword: {
      label: "Confirmar Contraseña",
      placeHolder: "Confirmar la contraseña",
    },
    passwordRules: {
      mustContains: "La contraseña debe contener:",
      eightChars: "Contiene al menos 8 caracteres.",
      oneCapital: "Contiene al menos una letra mayúscula.",
      oneSmall: "Contiene al menos una letra minúscula.",
      oneNumber: "Contiene al menos un número",
      special: "Contiene caracteres especiales !@",
    },
  },
  continueSubscription: {
    header: "Confirma tu información",
    description:
      "Ingrese su número de teléfono o su correo electrónico para verificar su cuenta y continuar con la suscripción.",
    emailOrPhone: {
      label: "Correo electrónico o número de teléfono",
      placeholder: "Introduce tu correo electrónico o número de teléfono",
    },
    confirm: "Confirmar",
    "didn'tSubscribe": "¿Tienes una cuenta?",
    subscribeNow: "Acceso",
  },
  userProfile: {
    firstName: "Nombre de pila",
    lastName: "Apellido",
    phoneNumber: "Número de teléfono",
    emailAddress: "Dirección de correo electrónico",
    editProfile: "Editar perfil",
  },
  home: {
    latestGifts: "Tus últimos regalos",
    giftsDetails: "Ver detalles de regalos",
    changeProgramStatus: "Cambiar estado del programa",
    currentProgram: "Programa actual",
    currentProgramDetails: "Ver detalles del programa",
    progress: "Progreso",
    currentDescription: "Tu viaje en {{programName}} programa, ya casi has terminado. ",
    endDate: "Fecha final",
    messageMedium: "Medio del mensaje",
    subscribe: "Suscribir",
    discoverProgram: "Programa Descubrir",
  },
  gifts: {
    table: {
      toolbar: {
        searchPlaceHolder: "Buscar",
        filters: "Filtros",
      },
      filtersDrawer: {
        program: "Programa",
        messageType: "Tipo de mensaje",
        messageStatus: "Estado del mensaje",
        applyFilters: "Aplicar filtros",
      },
      fields: {
        gift: "Regalo",
        sender: "Remitente",
        recipient: "Recipiente",
        program: "Programa",
        message: "Mensaje",
        type: "Tipo",
        status: "Estado",
        actions: "Comportamiento",
      },
      actions: {
        details: "Detalles",
        send: "Enviar",
      },
    },
    noGifts: "Aún no has recibido ni enviado ningún regalo. ",
    sendGiftButton: "Enviar regalo",
    giftPagination: {
      recipientForm: "Formulario de destinatario",
      yourInformation: "Tu información",
      back: "Atrás",
      next: "Próximo",
      submit: "Entregar",
      cardDesign: "1- Seleccionar diseño de tarjeta",
      cardColor: "2- Selecciona el color de tu tarjeta",
      changeProgram: "Cambiar programa",
      customizeYourGift: "Personaliza tu regalo",
      sendingType: "Tipo de envío",
    },
    form: {
      firstName: {
        label: "Nombre de pila",
        placeholder: "Introduce el nombre del destinatario",
      },
      lastName: {
        label: "Apellido",
        placeholder: "Introduce el apellido del destinatario",
      },
      email: {
        label: "Correo electrónico",
        placeholder: "Introduce el correo electrónico del destinatario",
      },
      preferredSendingChannel: "Canal de envío preferido",
      preferredSendingDate: "Fecha de envío preferida",
      customizeMessage: "Personaliza la experiencia del mensaje del destinatario",
      tellUsAboutU: "Primero, cuéntanos más sobre ti.",
      dateOfBirth: "Fecha de nacimiento",
      gender: "Género",
      islamicStatus: "estatus islámico",
      specialization: "Destinatario especializado",
      tellUsPreference: "Segundo, cuéntanos cómo prefieres recibir tu mensaje.",
      messageFrequency: "Frecuencia de mensajes del destinatario",
      topicLength: "Longitud del tema del programa",
      preferredLanguage: "Idioma preferido del programa",
      preferredTime: "Hora preferida del destinatario",
      giftMessage: "Mensaje de regalo",
      messageFrequencyHelperText:
        "Para obtener beneficios óptimos, establezca un horario de destinatarios diario",
      weekDay: "Día laborable",
      makeSure: "Asegúrese de que todos los campos obligatorios estén completos",
    },
    program: "Programa",
    filters: "Filtros",
    apply: "Aplicar filtros",
    reset: "Restablecer todos los filtros",
    yourGift: "Tu regalo",
    recipientInformation: "Recipiente de información",
    senderInformation: "Información del remitente",
    giftMessage: "Mensaje de regalo",
    formErrorMessages: {
      onlyLetters: "Este campo debe contener solo letras.",
      selectOneChannel: "Seleccione al menos un canal",
      validEmail: "Debe contener una dirección de correo electrónico válida.",
    },
  },
  badges: {
    done: "Bien hecho. ",
    inProgress: "Completa el mensaje de lectura para ganar la insignia.",
    noBadges: "No has ganado ninguna insignia hasta ahora. ",
    seePrograms: "Ver todos los programas",
    win: "tu ganas el {{programName}}  insignia",
    notWin: "¡Pulgares hacia arriba!  {{programName}} ",
  },
  settings: {
    tabs: {
      preferences: "Preferencias",
      programStatus: "Estado del programa",
      notifications: "Notificaciones",
      password: "Contraseña",
    },
    preferences: {
      editPreferences: "Editar preferencias",
      personalPreferences: "Preferencias personales",
      messagePreferences: "Preferencias de mensajes",
      preferenceItems: {
        dateOfBirth: "Fecha de nacimiento",
        gender: "Género",
        islamicStatus: "estatus islámico",
        specialized: "Especializado",
        topicLength: "Longitud del tema",
        programLanaguage: "Idioma del programa",
        messageMedium: "Medio del mensaje",
        messageTime: "hora del mensaje",
        messageFrequency: "Frecuencia de mensajes",
        weekDay: "Día laborable",
      },
    },
    programStatus: {
      change: "Cambiar",
      inProgress: "En curso",
      status: {
        notParticipated: "Aún no has participado en ningún programa",
        restart: "Su programa se reiniciará el",
        hold: "Su programa se detiene ahora y se reiniciará a las",
        completed: "Felicitaciones, ha completado el programa en",
        inProgress: "Su programa está en progreso",
      },
    },
    notifications: {
      saveChanges: "Guardar cambios",
      newProgramNotification: "Iniciar nuevo programa",
      finishProgramNotification: "Termina el programa",
      receivedGiftNotification: "Recibí un nuevo regalo.",
      winningBadgeNotification: "insignia ganadora",
      deliveredGiftNotification: "entregó el regalo",
    },
    password: {
      currentPassword: {
        title: "Contraseña actual",
        placeHolder: "Ingrese la contraseña actual",
      },
      newPassword: {
        title: "Nueva contraseña",
        placeHolder: "Ingrese la nueva contraseña",
      },
      confirmPassword: {
        title: "confirmar Contraseña",
        placeHolder: "Confirma la nueva contraseña",
      },
      cancel: "Cancelar",
      change: "Ahorrar",
      formErrorMessages: {
        passwordLength: "La contraseña debe tener al menos 8 caracteres",
        mustContainLowerCase: "La contraseña debe contener al menos una letra minúscula",
        mustContainUpperCase: "La contraseña debe contener al menos una letra mayúscula",
        mustContainNumber: "La contraseña debe contener al menos un número",
        mustContainSpecialChar: "La contraseña debe contener al menos un carácter especial",
        mustMatchPassword: "Debe coincidir con la nueva contraseña",
      },
    },
  },
  pastPrograms: {
    whatUSubcribeBefore: "Lo que te suscribes antes",
    restartProgram: "Reiniciar programa",
    subscribeAgain: "Suscríbete de nuevo",
    continueProgram: "Continuar programa",
    noPrograms: "No hay programas para mostrar aquí",
    areUSure: "¿Está seguro de que desea cambiar el estado del programa?",
    formErrorMessages: {
      restartDate: "Se requiere fecha de reinicio",
    },
    editPreferences: "Editar preferencias",
    cancel: "Cancelar",
  },
  changeCurrentProgramStatusDialog: {
    changeStatus: "Cambiar el estado del programa a",
    editPreferences: "Editar las preferencias del programa actual",
    editPreferencesShort: "Editar preferencias",
    subscribeDate: "Establece tu fecha de suscripción",
    programStatus: "Estado del programa *",
    restart: "Reanudar",
    holdLabel: "Sostener",
    unsubscribeLabel: "Darse de baja",
    restartDate: "Fecha de reinicio",
    cancel: "Cancelar",
    save: "Ahorrar",
    areUSure: "¿Está seguro de que desea cambiar el estado del programa?",
    setYourRestartDate: "Establece tu fecha de reinicio",
    hold: {
      restartInDate: "Reiniciar en una fecha específica",
    },
    unsubscribe: {
      reason: "Razón",
      notInterested: "ya no estoy interesado",
      needsImprovement: "El contenido necesita mejorar.",
      other: "otro",
    },
    restartContent: "Su programa se reiniciará a las ",
    holdContent: "Su programa se mantuvo exitosamente",
    unsubscribeContent: "Te has dado de baja exitosamente del programa.",
    programPreferences: "Cambiar preferencias del programa",
    formErrorMessages: {
      restartDate: "Se requiere fecha de reinicio",
    },
  },
  changeCurrentProgramPreferencesDialog: {
    editCurrentPrgPrf: "Editar las preferencias del programa actual",
    areUSure: "¿Está seguro de que desea cambiar las preferencias del programa?",
    saveChanges: "guardar cambios",
    cancel: "Cancelar",
    formErrorMessages: {
      oneChannel: "debes seleccionar un canal al menos",
    },
  },
  subscribeToNewProgramDialog: {
    header: "Suscríbete al programa",
    date: "Fecha de inicio",
    messageMedium: "Medio del mensaje",
    subscribe: "Suscribir",
    cancel: "Cancelar",
    areUSure: "¿Estás seguro de que quieres suscribirte a este programa?",
    formErrorMessages: {
      oneChannel: "debes seleccionar un canal al menos",
    },
  },
  changeGiftProgramDialog: {
    changeProgramHeader: "¿Quieres cambiar de programa?",
    chooseProgramHeader: "Elige un programa",
    cancel: "Cancelar",
    change: "Cambiar",
    add: "Agregar",
  },
  giftingPopup: {
    title: "¡Felicidades! Tu regalo ha sido bien personalizado y se enviará como prefieras.",
  },
  currentProgram: {
    description: "Tu viaje en {{programName}} programa, ya casi has comenzado. ",
    startDate: "Fecha de inicio",
    endDate: "Fecha final",
    lastMessageOpen: "Último mensaje abierto en",
    frequency: "Frecuencia",
    time: "Tiempo",
    messageMedium: "Medio del mensaje",
    editPreferences: "Editar preferencias",
    messagesLeft: "Mensajes dejados",
    congratulations: "Felicidades",
    latestMessages: "Últimos mensajes",
    noPrograms: "No hay programas para mostrar aquí",
  },
  allPrograms: {
    programName: "Nombre del programa",
    programImage: "Imagen del programa",
  },
  statistics: {
    recivedGiftCount: "Donaciones totales",
    totalMessagesCount: "Mensajes totales",
    userWhatsappMessagesCount: "Mensajes de WhatsApp",
    userEmailMessagesCount: "Mensajes de correo electrónico",
    userSmsMessagesCount: "Mensajes SMS",
    userProgramsCount: "Programas totales",
    "Gifts count": "Donaciones totales",
    "Whatsapp message gifts count": "Mensajes de WhatsApp",
    "Email message gifts count": "Mensajes de correo electrónico",
    "Sms message gifts count": "Mensajes SMS",
  },
  telegramBot: {
    title: "Bot de Telegrama",
    description:
      "Utilice el bot de Telegram para recibir mensajes que resuma las ideas principales que presentamos en los correos electrónicos, para que sea ligero y fácil de entender el significado central del tema.",
    subtitle:
      "Para comenzar a recibir mensajes de Telegram, deberá comenzar a utilizar nuestro bot. ",
    subtitle1: "1. Haga clic en el botón Conectar Telegram.",
    subtitle2: "2. Comience a usar el bot Days of Iman Telegram.",
    button: "Conectar telegrama",
  },
  yes: "Sí",
  no: "No",
  completedSuccessfully: "Completado satisfactoriamente",
  date: "Fecha",
  messageChannel: "Canal de mensajes",
};

export default spanishJson;
